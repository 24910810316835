import React from 'react';
import KendoView from '../../components/crud/kendo';
import { authenticationService } from '../../components/authorization';

const config = [

    { field: 'paidDate', title: 'Payment Date', type: 'date', isGrid: true },
    { field: 'activityCode', title: 'Activity Code', isGrid: true },
    { field: 'functionCode', title: 'Function Code', isGrid: true },
    { field: 'revenueCode', title: 'Revenue Code', isGrid: true },
    { field: 'name', title: 'Activity Name', isGrid: true },
    { field: 'dotnumber', title: 'DOT Number', isGrid: true },
    { field: 'companyName', title: 'Company Name', isGrid: true },
    { field: 'vendorCode', title: 'Company Code', isGrid: true },
    // { field: 'BillingCycles', title: 'Billing Cycles', isGrid: true },
    { field: 'cameraEvent', title: 'Camera Event ?', type: 'checkbox', isGrid: true },
    { field: 'invoiceCycle', title: 'Invoice Cycle', isGrid: true },
    { field: 'invoiceNumber', title: 'Invoice Number', isGrid: true },
    { field: 'charge', title: 'Charge', type: 'amount', isGrid: true },
    // { field: 'BillingCycles', title: 'Billing Cycles', isGrid: true },
    { field: 'amountPaid', title: 'Total Amount', type: 'amount', isGrid: true },
    { field: 'invoiceDate', title: 'Invoice Date', type: 'date', isGrid: true },
    { field: 'billedDate', title: 'Billed Date', type: 'date', isGrid: true },
    { field: 'status', title: 'Status', isGrid: true },
]



export default function SkuActivity() {
    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`
    return <KendoView grid='SKU_ACTIVITY_REPORT' param='id' sort='invoiceDate' link='#' url={process.env.REACT_APP_KENDO_API + '/api/Reports/SkuActivity' + tokenParam} columns={config} title='Sku Activity Report ' />
}