import React, { useRef, useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { GetGridConfig, SaveGridConfig } from '../../REST/administration';
import { SendEventEmail } from '../../REST/cameraEvents';
import EmailTemplate from '../../Modules/CameraImages/emailTemplate';
import './kendo.css';
import Axios from 'axios';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { blue } from '@material-ui/core/colors';
import SpeedTemplate from '../templates/speeddial';
import EmailIcon from '@material-ui/icons/Email';

//import '../../EventsTemplate.css'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 2000,
        color: '#fff',
    }
}));
function KendoView({ url = null, columns = [], title = "Not Provided", sort = 'id', grid = null, component, back = null, param, onClick }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [reset, setReset] = useState(false);
    const [download, setDownload] = useState(false);
    const [config, setConfig] = useState(null)
    const frameView = useRef(null);
    const [showPopUp, setShowPopUp] = useState(false);
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [mailBody, setmailBody] = useState('');
    const params = useLocation();
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        GetGridConfig(grid).then(resp => {
            if (resp.data) {
                // Change Tokens
                const gridConfig = JSON.parse(resp.data.config)
                try {
                    gridConfig.dataSource.transport.read.url = url
                    setConfig(JSON.stringify(gridConfig))
                    setDownload(false)
                }
                catch (ex) {
                    console.log(ex)
                }
            }
        }).catch(ex => {
            console.log(ex)
            setDownload(false)
        })
    }, [grid, url])
    useEffect(() => {
        const messageHandler = (message) => {
            console.log('message', message, process.env.REACT_APP_PROD_URL)
            // if (message.origin === process.env.REACT_APP_PROD_URL) {
            if (message.data === 'Initialized') {
                if (frameView.current && frameView.current.contentWindow) {
                    frameView.current.contentWindow.postMessage({ columns, url, param, sort, settings: config }, "*")
                }
            }
            else if (message.data.config) {
                const entity = { gridId: grid, config: message.data.config, userId: 0 };
                SaveGridConfig(entity).then(resp => {
                    console.log(resp.data)
                })
            }
            else if (message.data.selected) {
                console.log('received message', message)
                if (onClick && typeof onClick === 'function')
                    onClick(message.data.selected)
            }
            else if (message.data.message == 'get_selected_data') {
                console.log('getselecteddata', message.data.selectedImageRow)
                setSelectedRows(message.data.selectedImageRow);
                setShowPopUp(true);
            }
            else if (message.data.message == 'send email') {
                console.log(message, 'array data')

                 

                let htmltext = `<div>
                ${message.data.selectedImageRow?.map((obj) => {
                    return (
                        `<table class="maintable">
                    <tr>
                        <td class="image-column">
                            <img src="https://gcn-camera.s3.amazonaws.com/${obj['image_Uid']}_context.jpg" alt="Image"/>
                        </td>
                        <td class="content-column">
                            <table>
                                <tr>
                                    <td>City : ${obj['city']}</td>
                                </tr>
                                <tr>
                                    <td>License Plate : ${obj['plate_Number']}</td>
                                </tr>
                                <tr>
                                    <td>Activity Date : ${obj['createdOn']}</td>
                                </tr>
                                <tr>
                                    <td>Camera Name : ${obj['cameraName']}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    </table>`);
                })}
                    </div>`
                SendEventEmail({
                    html: `<!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <style>
                            
                            table {
                                border-collapse: collapse;
                                width: 100%;
                                page-break-inside:auto
                            }
                            tr    { page-break-inside:avoid; page-break-after:auto }
                            
                            .maintable td {
                                border: 1px solid #d2d2d2;
                                padding: 10px;
                                text-align: left;
                                
                            }
                            .content-column   td
                            {
                                border:none;
                                border-bottom: 1px solid #C2C2C2;
                            }
                            .content-column tr:last-child td{
                                border:none;
                            }
                            th, td {
                                padding: 8px;
                                text-align: left;
                            }
                            .image-column {
                                width: 40%;
                            }
                            .content-column {
                                width: 60%;
                            }
                            img {
                                max-width: 100%;
                                height: auto;
                            }
                            
                        </style>
                    </head>
                    <body>${htmltext}</body></html>`,
                    email: email,
                    body: mailBody,
                    subject: subject
                }).then(response => {
                    console.log('response--->', response);
                    setEmail('');
                    setSubject('');
                    setmailBody('')
                    frameView.current.contentWindow.postMessage({ message: 'refresh_grid' }, "*")

                })

            }

            else if (message.data) {
                console.log('received message', message)
                if (onClick && typeof onClick === 'function')
                    onClick(message.data)
            }

        }
        if (download === false)
            window.addEventListener('message', messageHandler)
        return function cleanup() {
            console.log('Cleaned')
            window.removeEventListener('message', messageHandler);
        }
    }, [url, columns, param, onClick, sort, download, config, grid, reset, email, subject, mailBody])

    const resetGrid = () => {
        localStorage.removeItem('griddata')
        setReset(true)
        setConfig(null)
        setTimeout(() => {
            setReset(false)
        }, 5000)
    }


    const saveGrid = () => {
        if (frameView.current && frameView.current.contentWindow) {
            frameView.current.contentWindow.postMessage({ save: true }, "*")
        }
    }

    const getSelectedData = (data) => {
        console.log('getSelectedData', data);
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    if (reset === true)
        return <p>Resetting Grid...</p>


    return <div className='col-12 p-2' style={{ height: 'calc(100% - 100px)' }}>

        <div className='row'>
            <div className='col-6'>
                <h5>{title} {component}</h5>

            </div>
            <div className='col-6'>
                <ButtonGroup className='float-right' color='secondary'>
                    {back && <Button href={back} startIcon={<BackIcon />}>Back</Button>}
                    <Button title='Save Grid Layout' onClick={saveGrid}>Save</Button>
                    <Button title='Reset Grid Layout' onClick={resetGrid}>Reset</Button>
                    {grid == 'CAMERA_GRID' && <Button title='Camera Images' href={`${process.env.REACT_APP_PROD_URL}` + "/#/eventsimages"} >Camera Images</Button>}
                     
                </ButtonGroup>
                <div className='clearfix' />
            </div>

        </div>

        <br />
        <Backdrop className={classes.backdrop} open={loading} onClick={() => setLoading(false)}><CircularProgress color="inherit" /></Backdrop>
        <iframe onLoad={() => setLoading(false)} src={`${process.env.REACT_APP_GRID_URL}`} ref={frameView}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            width="100%"
            height="100%"
            title='Message Viewer'
            scrolling="auto"
            getSelectedData={getSelectedData}>
        </iframe>
        <div style={{position:'absolute',top:40,right:30}}>
        {grid == 'CAMERA_GRID_IMAGES' ? <SpeedTemplate back entityId={'emailTemplate'} link='#events' entity={{}} access={false} actions={[
            {
                name: 'Email Template', icon: <EmailIcon/>, module: 9, component: <EmailTemplate data={selectedRows} frameView={frameView} />
            },
        ]} hideBack={true}/> : <></>}
        </div>
        
    </div>
}


export default KendoView