import Axios from './base';

export const GetEvent = (id) => Axios().get('CameraEvent/' + id);

export const GetEvents = (id) => Axios().get('CameraEvent/All/' + id);

export const GetEventInvoices = (id) => Axios().get('CameraEvent/Statements/' + id);

export const AddEvent = (entity) => Axios().post('CameraEvent', entity);

export const SendEventEmail = (entity) => Axios('Mail sent successfully', 'Mail sending failed').post('CameraEvent/Email', entity);


export const UpdateEvent = (entity) => Axios().put('CameraEvent', entity);

export const UpdateStatus = (id, status) => Axios().get('CameraEvent/Status/' + id + '/' + status)

export const UpdateEventCharge = (id, charge) => Axios().get('CameraEvent/Charge/' + id + '/' + charge)


export const GetOptions = () => Axios().get('CameraEvent/Options');

export const AddCompany = (entity) => Axios().post('CameraEvent/Company', entity);

export const CreateInvoice = (id, type) => Axios().get('CameraEvent/Invoice/' + id + '/' + type);

export const SearchEvents = (entity) => Axios(null, 'No Events Found').post('CameraEvent/Search', entity);

export const Validate = (id) => Axios().get('CameraEvent/Validate/' + id);

export const GetCharge= async (entity,id) => await Axios('Event Updated', 'No Company Found').post('CameraEvent/Charges/' + id, entity)