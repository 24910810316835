import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import ListIcon from '@material-ui/icons/List';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 70,
        // transform: 'translateZ(-10px)',
        flexGrow: 1,
        position: 'relative'
    },
    speedDial: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 1000
    },
    backdrop: {
        zIndex: 999,
        color: '#fff',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function SpeedTemplate({ access = [], actions = [], link = '#', entity = null, entityId, back = false,hideBack=false }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [current, setCurrent] = React.useState({ component: null, title: null, module: -1 });
    const history = useHistory();

    const toggleOpen = () => {
        setOpen(!open);

    };

    const handleAction = (component, title, module) => event => {
        setCurrent({ component, title, module });
        setShow(true);
    }

    const handleHide = () => {
        setShow(false)
        setCurrent({ component: null, title: null, module: -1 });
    }

    const getPermissions = (module) => {
        if(access){

            const item = access?.filter(e => !e.isStatus).find(e => e.module === module)
            return { editable: item ? item.allowEdit : false, display: item ? (item.allowDisplay && (item.displayType === 3 || item.displayType === '3')) : false }
        }
        else {
            return { editable: true, display: true }

        }
    }

    const items = actions.filter(e => {
        if(access){

            const { display } = getPermissions(e.module)
            return display;
        }
        else {
            return true;
        }
    })

    if (items.length > 0)
        return <React.Fragment>
            <Backdrop className={classes.backdrop} open={open} />
            <div className={classes.root}>
                <Dialog fullScreen open={show} onClose={handleHide} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar} color='secondary'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleHide} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {current.title || 'NA'}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {current.component && React.cloneElement(current.component || <p />, { ...getPermissions(current.module), entity, entityId })}
                </Dialog>
                {back && !hideBack && <Button variant='contained' onClick={() => { history.goBack() }} color='secondary' startIcon={<ArrowBack />}>Back</Button>}
                {!back && !hideBack && <Button variant='contained' href={link} color='secondary' startIcon={<ArrowBack />}>Back</Button>}
                <SpeedDial
                    className={classes.speedDial}
                    ariaLabel="SpeedDial Template"
                    icon={open === false ? <ListIcon /> : <CloseIcon />}
                    FabProps={{ color: 'secondary', size: 'small' }}
                    onClick={toggleOpen}
                    open={open}
                    direction='down'
                >
                    {items.map((action, idx) => (
                        <SpeedDialAction key={action.name} tooltipOpen icon={action.icon} onClick={handleAction(action.component, action.name, action.module)} tooltipTitle={action.name} />
                    ))}
                </SpeedDial>
            </div>
        </React.Fragment>
    return hideBack? <Button variant='contained' onClick={() => { history.goBack() }} color='secondary' startIcon={<ArrowBack />}>Back</Button>:<Button variant='contained' href={link} color='secondary' startIcon={<ArrowBack />}>Back</Button>
}