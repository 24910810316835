import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import CRUDView from '../../../components/crud';
import BackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useParams, useHistory } from 'react-router-dom';
import { GetSku, AddSku, UpdateSku, GetPermits, GetCameras, GetPricing } from '../../../REST/administration';
import { FormView, Field } from '../../../components/form/Index';

const triggers = [
    { label: 'Camera Event', value: 1 },
    { label: 'User Input', value: 2 },
    { label: 'Fixed Amount', value: 3 }
]

const types = [
    { label: 'Count', value: 1 },
    { label: 'Amount', value: 2 }
]

const permitFreq = [
    { label: 'Daily', value: 1 },
    { label: 'Monthly', value: 2 },
    { label: 'Yearly', value: 3 },
    { label: 'Each', value: 4 },
]

export default function SkuView() {
    const { id } = useParams();
    const [permits, setPermits] = useState([]);
    const [cameras, setCameras] = useState([]);
    const [pricing, setPricing] = useState([]);
    const history = useHistory();

    useEffect(() => {
        GetPermits().then(resp => {
            if (Array.isArray(resp.data))
                setPermits(resp.data.map(e => { return { label: e.name, value: e.id } }))
        })
        GetCameras().then(resp => {
            if (Array.isArray(resp.data))
                setCameras(resp.data.map(e => { return { label: e.name, value: e.id } }))
        })
        GetPricing().then(resp => {
            if (Array.isArray(resp.data))
                setPricing(resp.data.map(e => { return { label: e.name, value: e.id } }))
        })
    }, [])

    const config = [
        { field: 'name', title: 'Sku Name', type: 'text', isGrid: true },
        { field: 'description', title: 'Description', type: 'textarea', isGrid: true },
        { field: 'triggerType', title: 'Trigger and Fee', type: 'text', isGrid: true },
    ]

    const actions = [
        { icon: AddIcon, tooltip: 'New Record', isFreeAction: true, onClick: () => history.push('/admin/skus/0') },
        { icon: EditIcon, tooltip: 'Edit Record', onClick: (evt, rowData) => history.push('/admin/skus/' + rowData.id) }
    ]

    if (id)
        return <DetailsView permits={permits} cameras={cameras} pricing={pricing} />

    return <CRUDView link='#admin' url={`${process.env.REACT_APP_API_URL}/Sku`} columns={config} title="SKU's" actions={actions}
        allowEdit={false}
        allowAdd={false}
        allowDelete={false}
        id='id' />
}

function DetailsView({ options = [], permits = [], cameras = [], pricing = [] }) {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const history = useHistory();
    const formView = useRef(null);

    useEffect(() => {
        if (id !== 0 && id && id !== '0')
            GetSku(id).then(response => setData(response.data))
    }, [id])

    const handleSave = () => {
        let result = formView.current.getResult();
        if (result) {
            if (id === 0 || id === '0')
                handleAdd(result)
            else
                handleUpdate(result)
        }
    }

    const handleAdd = (result) => {
        AddSku(result).then(response => {
            debugger;
            history.push('/admin/skus/' + response.data.id)
        })
    }

    const handleUpdate = (result) => {
        UpdateSku({ ...data, ...result })
    }

    const handleChange = () => {
        let result = formView.current.getCurrentResult();
        setData({ ...data, ...result });
    }

    return <div className='row'><div className='col-6'>
        <FormView ref={formView} data={data}>
            <div className='row'><div className='col-12'>
                <Button className='float-right' variant="contained" color="secondary" startIcon={<BackIcon />} href='#admin/skus'>Back</Button>
                <div className='clearfix' />
            </div></div>
            <Field.TextBox label='Name' required field='name' />
            <Field.TextArea label='Description' required field='description' />
            <div className='row'>
                <Field.TextBox className='col' label='Function Code' required field='functionCode' />
                <Field.TextBox className='col' label='Revenue Code' required field='revenueCode' />
                <Field.TextBox className='col' label='Activity Code' required field='activityCode' />
            </div>
            <div className='row'>
                <Field.DropDown className='col' changeEvt={handleChange} label='Trigger and Fee' required field='triggerType' options={triggers} />
                {data && data.triggerType !== 1 && data.triggerType !== '1' && <Field.AmountText precision={5} className='col' label='Amount' required field='amount' />}
            </div>
            {data && (data.triggerType === 1 || data.triggerType === '1') && <div className='row'>
                <Field.MultiSelect className='col-12' label='Entry Cameras' options={cameras} required field='cameraId' string />
                <Field.MultiSelect className='col-12' label='Exit Cameras' options={cameras} required field='exitCameraId' string />
                <Field.DropDown className='col-12' label='Permit Type' options={permits} required field='permitType' />
                <div className='col-md-6 col-sm-12'>
                    <div className='card'>
                        <div className='card-body p-1'>
                            <h6 className='card-title'>If Permitted</h6>
                            <Field.DropDown changeEvt={handleChange} className='col' label='Charge Frequency' options={permitFreq} required field='permitId' />
                            <Field.AmountText className='col' label='Default Amount' required field='permitAmount1' />
                            {/* {data && String(data.permitId) === '4' && <div className='row'>
                                <Field.TextBox className='col-12' label='Maximum Time (In Minutes)' required field='permitTime' number />
                                <Field.AmountText className='col-12' label='Additional Amount' required field='permitAmount2' />
                            </div>} */}
                            <Field.DropDown changeEvt={handleChange} className='col' label='Pricing' options={pricing} required field='permitPricingSku' />
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <div className='card'>
                        <div className='card-body p-1'>
                            <h6 className='card-title'>If Not Permitted</h6>
                            <Field.DropDown changeEvt={handleChange} className='col' label='Charge Frequency' options={permitFreq} required field='nonPermitId' />
                            <Field.AmountText className='col' label='Default Amount' required field='nonPermitAmount1' />
                            {/* {data && String(data.nonPermitId) === '4' && <div className='row'>
                                <Field.TextBox className='col-12' label='Maximum Time (In Minutes)' required field='nonPermitTime' number />
                                <Field.AmountText className='col-12' label='Additional Amount' required field='nonPermitAmount2' />
                            </div>} */}
                            <Field.DropDown changeEvt={handleChange} className='col' label='Pricing' options={pricing} required field='nonPermitPricingSku' />
                        </div>
                    </div>
                </div>
            </div>}
            {data && (data.triggerType === 2 || data.triggerType === '2') && <div className='row'>
                <Field.DropDown unordered className='col' label='User Input Type' required field='userInputType' options={types} />
                <Field.CheckBox className='col' label='Customer can edit count or amount' field='userCanEdit' />
            </div>}
            <div className='row'>
                <div className='col'>
                    <Button fullWidth variant='outlined' color='secondary' onClick={handleSave}>Save</Button>
                </div>
                <div className='col'>
                    <Button fullWidth variant='outlined' color='secondary' href='#admin/skus'>Back</Button>
                </div>
            </div>
        </FormView >
    </div>
    </div>
}
