import React from 'react';
import KendoView from '../../components/crud/kendo';
import { authenticationService } from '../../components/authorization';

const config = [
    { field: 'id', title: 'Id', isGrid: true },
    { field: 'emailSubject', title: 'Subject', isGrid: true },
    { field: 'notificationName', title: 'NotificationName', isGrid: true },   
    { field: 'emailId', title: 'MailReceivedTo', isGrid: true },    
    { field: 'createdOn', title: 'Date Sent', type: 'datetime', isGrid: true },   
    { field: 'emailBody', title: 'Body', isGrid: true , width: '600px'},
]



export default function notificationlog() {
    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`
    return <KendoView grid='NOTIFICATIONLOG_REPORT' param='id' sort='createdOn' link='#' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Notificationlog' + tokenParam} columns={config} title='Notification Log' />
}