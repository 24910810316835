import React from 'react';
import CRUDView from '../../../components/crud';


export default function Cameras() {

    const opts = [
        { label: 'Group 1', value: 1 },
        { label: 'Group 2', value: 2 },
        { label: 'Group 3', value: 3 },
        { label: 'Group 4', value: 4 }
    ]

    const config = [
        { field: 'name', title: 'Camera Name', type: 'text', isGrid: true },
        { field: 'description', title: 'Description', type: 'textarea', isGrid: true },
        { field: 'cameraId', title: 'Camera ID', type: 'text', required: true, isGrid: false },
        { field: 'cameraIp', title: 'Camera IP', type: 'ipNumber', required: true, isGrid: true },
        { field: 'cameraPort', title: 'Camera Port', type: 'numeric', required: true, isGrid: false },
        { field: 'cameraUrl', title: 'Camera URL', type: 'text', required: true, isGrid: false },
        { field: 'isEnter', title: 'Entry Camera ?', type: 'checkbox', required: false, isGrid: true },
        { field: 'cameraGroup', title: 'Camera Group', type: 'select', required: true, isGrid: true, options: opts },
    ]

    return <CRUDView link='#admin' url={`${process.env.REACT_APP_API_URL}/Camera`} columns={config} title='Cameras' allowDelete={false} id='id' />
}

