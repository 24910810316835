import React, { Fragment, useState, useRef, useEffect } from 'react';
import KendoView from '../../components/crud/kendo';
import { FormView, Field } from '../../components/form/Index';
import { GetSkus } from '../../REST/company';
import { authenticationService } from '../../components/authorization';

const config = [
    { field: 'chargeDescription', title: 'Sku Name', type: 'text', isGrid: true },
    { field: 'invoiceNumber', title: 'Invoice Number', isGrid: true },
    { field: 'functionCode', title: 'Function Code', isGrid: true },
    { field: 'revenueCode', title: 'Revenue Code', isGrid: true },
    { field: 'amount', title: 'Amount Paid', type: 'amount', isGrid: true },
    { field: 'reportedMonth', title: 'Reported Month', type: "text", isGrid: true },
    { field: 'reportedYear', title: 'Reported Year', type: "text", isGrid: true },
    { field: 'reportedDate', title: 'Reported Date', type: "date", isGrid: true },
]

export default function Ageing() {
    const [item, setItem] = useState(null);
    const [option, setSkus] = useState([]);
    const [data, setData] = useState(null);
    const formView = useRef(null);
    const [open, setOpen] = useState(true)
    useEffect(() => {
        GetSkus().then(res => {
            setSkus(res.data.map(i => { return { label: i.name, value: i.id } }))
        })
    }, [])

    const handleChange = (x) => {
        setItem(x);
        setData({ sku: x })
        setOpen(false)
        setTimeout(() => {
            setOpen(true)
        }, 500)
    }

    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`


    return <Fragment>
        {option && option.length > 0 && <FormView ref={formView} data={data}>
            <Field.DropDown className='col-xs-12 col-sm-4 col-md-3' label='Select Skus' changeEvt={handleChange} required field='sku' options={option} />
        </FormView>}
        {open && item && <KendoView grid='SKU_REPORT' param='id' link='#' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Sku/' + tokenParam + '&id=' + item} columns={config} title='Sku Report ' />}
    </Fragment>
}