import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { AddVehicles } from '../../REST/application';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    backdrop: {
        zIndex: 2000,
        color: '#fff',
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open = false, onClose, id }) {
    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    const frameView = useRef(null);

    useEffect(() => {
        if (open)
            setLoading(true)
        else
            setLoading(false)
    }, [open])

    useEffect(() => {
        const messageHandler = (message) => {
           // if (message.origin === process.env.REACT_APP_PROD_URL) {
                if (message.data === 'Initialized') {
                    if (frameView.current && frameView.current.contentWindow) {
                        setLoading(false);
                    }
                }
                else if (message.data)
                    if (Array.isArray(message.data))
                        AddVehicles(id, message.data).then(onClose).catch(ex => {
                            if (ex && ex.response && ex.response.data)
                                if (Array.isArray(ex.response.data))
                                    frameView.current.contentWindow.postMessage(ex.response.data, "*")
                        })
           // }
        }
        window.addEventListener('message', messageHandler)

        return function cleanup() {
            console.log('Cleaned')
            window.removeEventListener('message', messageHandler);
        }
    }, [id, onClose])

    return (
        <div>
            <Backdrop className={classes.backdrop} open={loading} onClick={() => setLoading(false)}><CircularProgress color="inherit" /></Backdrop>
            <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
                <AppBar color='secondary' className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Add Vehicles
                        </Typography>
                    </Toolbar>
                </AppBar>
                <iframe id='template-editor' src={`${process.env.REACT_APP_GRID_URL}/#/jexcel`} ref={frameView}
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    width="100%"
                    height="100%"
                    title='Template Editor'
                    scrolling="auto">
                </iframe>
            </Dialog>
        </div>
    );
}