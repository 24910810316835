import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormView, Field } from '../../../components/form/Index';
import { useRef } from 'react';
import { SendEmail, GetEmail } from '../../../REST/widget';
import { useState } from 'react';

export default function FormDialog({ noticeId = 0, module = 0, entityId = 0, open = false, handleClose }) {
    const formView = useRef(null);
    const [email, setEmail] = useState(null)
    const sendEmail = () => {
        let result = formView.current.getResult();
        if (result) {
            debugger
            SendEmail(noticeId, module, entityId, result).then(handleClose)
        }
    }

    useEffect(() => {
        if (open) {
            GetEmail(module, entityId).then(resp => {
                if (resp.data) {
                    console.log(resp.data)
                    setEmail(resp.data)
                }
            })
        }
    }, [open, module, entityId])

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Email Attachment</DialogTitle>
        <DialogContent>
            <FormView ref={formView} data={{ value: email }}>
                <Field.TextBox field='value' required label='Email' />
                <Field.TextBox field='label' required label='Subject' />
            </FormView>
        </DialogContent>
        <DialogActions>
            <Button onClick={sendEmail} color="primary">
                Send
          </Button>
            <Button onClick={handleClose} color="primary">
                Close
          </Button>
        </DialogActions>
    </Dialog>
}
