import React, { useState, useEffect, forwardRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import KendoView from '../../components/crud/kendo';
import DetailsView from './details';
import CommentsView from './comments';
import VehiclesView from './vehicles';
import SkuView from './sku';
import EventsView from './events';
import TotalView from './total';
import Company from './company';
import { GetAllItems, GetInvoice, UpdateStatus } from '../../REST/statement';
import { GetPermissions } from '../../REST/security';
import { authenticationService } from '../../components/authorization';
import ModuleTemplate from '../../components/templates/module';
import StatusTemplate from '../../components/templates/status';
import SpeedTemplate from '../../components/templates/speeddial';
import ReceiptsDetails from './explain';
import TemplateView from './template';
// Icons
import FeedbackIcon from '@material-ui/icons/Feedback';
import BusinessIcon from '@material-ui/icons/Business';
import CommentIcon from '@material-ui/icons/Comment';
import NotesIcon from '@material-ui/icons/NoteAdd';
import PayIcon from '@material-ui/icons/Payment';
import ListIcon from '@material-ui/icons/List';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DescriptionIcon from '@material-ui/icons/Description';
import ReceiptIcon from '@material-ui/icons/Receipt';
// Other Modules
import NotesView from '../Widgets/Notes';
import NoticeView from '../Widgets/Notices';
import AttachmentView from '../Widgets/Files';

export default function Statement() {
    const { id } = useParams();
    const history = useHistory();
    const config = [
        { field: 'invoiceNumber', title: 'Invoice Number', isGrid: true },
        { field: 'name', title: 'Invoice Cycle/Permit Type', isGrid: true },
        { field: 'companyName', title: 'Company Name', isGrid: true },
        { field: 'dotNumber', title: 'DOT Number', isGrid: true },
        { field: 'fiscalYear', title: 'Fiscal Year', isGrid: true },
        { field: 'status', title: 'Status', isGrid: true },
        { field: 'invoiceDate', title: 'Invoice Date', type: "datetime", isGrid: true },
        { field: 'dueDate', title: 'Due Date', type: "datetime", isGrid: true },
        { field: 'paidDate', title: 'Payment Date', type: "datetime", isGrid: true },
        { field: 'finalizeDue', title: 'Finalize Due', type: "datetime", isGrid: true },
        { field: 'amountDue', title: 'Amount Due', type: 'numeric', isGrid: true },
        { field: 'amountPaid', title: 'Amount Paid', type: 'numeric', isGrid: true },
        { field: 'total', title: 'Total Amount', type: 'numeric', isGrid: true }
    ]

    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`

    const url = process.env.REACT_APP_KENDO_API + '/api/Reports/Invoice' + tokenParam

    if (id)
        return <StatementView />
    return <KendoView grid='STATEMENT_GRID' back='#' sort='invoiceNumber' title='Invoices' url={url} param='id' onClick={(id) => history.push('/invoices/' + id)} columns={config} />

}

const StatementCharges = forwardRef(({ editable = true, items = [], display = true, handleSelect, handleRefresh, refresh = false, selected }, ref) => {
    console.log(refresh)
    return <React.Fragment>
        <VehiclesView editable={editable} charges={items} handleRefresh={handleRefresh} selected={selected} refresh={refresh} onSelect={handleSelect} />
        <EventsView editable={true} charges={items} handleRefresh={handleRefresh} selected={selected} refresh={refresh} onSelect={handleSelect} />
        <SkuView editable={editable} charges={items} handleRefresh={handleRefresh} selected={selected} refresh={refresh} onSelect={handleSelect} />
    </React.Fragment>
})


function StatementView() {
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState([]);
    const [details, setDetails] = useState(null);
    const [access, setAccess] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const { id } = useParams();
    const role = authenticationService.getRole();

    useEffect(() => {
        GetAllItems(id).then(response => {
            if (Array.isArray(response.data)) { setItems(response.data) }
        })
    }, [id, refresh])

    useEffect(() => {
        GetInvoice(id).then(response => {
            console.log(response.status)
            if (response.data != null && response.status !== 204)
                GetPermissions(4, response.data.statusValue, role).then(resp => {
                    if (Array.isArray(resp.data)) {
                        setDetails(response.data);
                        setAccess(resp.data);
                    }
                })
        })
    }, [id, role, refresh])

    const handleStatus = (status) => {
        UpdateStatus(id, status).then(() => setRefresh(!refresh))
    }

    const handleRefresh = () => setRefresh(!refresh)

    const handleSelect = (item) => {
        const exist = selected.findIndex(e => e.module === item.module && e.entityId === item.entityId)
        if (exist < 0)
            setSelected(selected.concat([item]))
        else setSelected(selected.filter(e => e.module === item.module && e.entityId !== item.entityId))
    }

    const actions = [
        { name: 'Details', icon: <DescriptionIcon />, module: 1, component: <DetailsView /> },
        { name: 'Company', icon: <BusinessIcon />, module: 10, component: <Company /> },
        { name: 'Comments', icon: <FeedbackIcon />, module: 2, component: <CommentsView /> },
        { name: 'Charges', icon: <ListIcon />, module: 3, component: <StatementCharges refresh={refresh} handleRefresh={handleRefresh} selected={selected} handleSelect={handleSelect} /> },
        { name: 'Payment', icon: <PayIcon />, module: 4, component: <TotalView charges={items} handleRefresh={handleRefresh} selected={selected} refresh={refresh} /> },
        { name: 'Notes', icon: <CommentIcon />, module: 5, component: <NotesView module={4} entityId={id} /> },
        { name: 'Notices', icon: <NotesIcon />, module: 6, component: <NoticeView module={4} entityId={id} /> },
        { name: 'Attachments', icon: <AttachmentIcon />, module: 7, component: <AttachmentView module={4} entityId={id} /> },
        { name: 'Template', icon: <FeedbackIcon />, module: 9, component: <TemplateView /> },
        { name: 'Receipt Details', icon: <ReceiptIcon />, module: 8, component: <ReceiptsDetails module={3} entityId={id} /> },
    ]
    /*Need to Change templates below. Make it One*/

    return <React.Fragment>
        <SpeedTemplate back entityId={id} link='#invoices' entity={details} access={access} actions={actions} />
        <StatusTemplate settings={access} handleClick={handleStatus}>
            <ModuleTemplate entity={details} settings={access} module={1} component={<DetailsView />} />
            <ModuleTemplate entity={details} settings={access} module={10} component={<Company />} />
            <ModuleTemplate entity={details} settings={access} module={2} component={<CommentsView entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={3} component={<StatementCharges refresh={refresh} handleRefresh={handleRefresh} selected={selected} handleSelect={handleSelect} />} />
            <ModuleTemplate entity={details} settings={access} module={4} component={<TotalView charges={items} handleRefresh={handleRefresh} selected={selected} refresh={refresh} />} />
            <ModuleTemplate entity={details} settings={access} module={5} component={<NotesView module={4} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={6} component={<NoticeView module={4} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={7} component={<AttachmentView module={4} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={9} component={<TemplateView />} />
            <ModuleTemplate entity={details} settings={access} module={8} component={<ReceiptsDetails module={3} entityId={id} />} />
        </StatusTemplate>
    </React.Fragment>
}