import React, { useEffect, useState } from 'react';
import { GetPermit, UpdateStatus } from '../../REST/permits';
import { GetPermissions } from '../../REST/security';
import { useParams, useHistory } from 'react-router-dom';
import { authenticationService } from '../../components/authorization';
import ModuleTemplate from '../../components/templates/module';
import StatusTemplate from '../../components/templates/status';
import SpeedTemplate from '../../components/templates/speeddial';
import KendoView from '../../components/crud/kendo';
import Company from './company';
import Details from './details';
import Insurance from './insurance';
import Vehicles from './vehicles';
import Documents from './documents';
// Icons
import CommentIcon from '@material-ui/icons/Comment';
import NotesIcon from '@material-ui/icons/NoteAdd';
import CommuteIcon from '@material-ui/icons/Commute';
import AttachmentIcon from '@material-ui/icons/Attachment';
import BusinessIcon from '@material-ui/icons/Business';
import InfoIcon from '@material-ui/icons/Info';
import DescriptionIcon from '@material-ui/icons/Description';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FilesIcon from '@material-ui/icons/FileCopy';
// Other Modules
import NotesView from '../Widgets/Notes';
import NoticeView from '../Widgets/Notices';
import AttachmentView from '../Widgets/Files';
import ReceiptView from '../Widgets/Receipt';

export default function Permit() {
    const history = useHistory();
    const { id } = useParams();

    const columns = [
        { title: 'Permit Id', field: 'id' },
        { title: 'Application Type', field: 'name' },
        { title: 'Bus Number', field: 'busNumber' },
        { title: 'Plate Number', field: 'plateNumber' },
        { title: 'Plate Region', field: 'plateRegion' },
        { title: 'Status', field: 'status' },
        { title: 'Expiry Date', field: 'expiryDate' },
        { field: 'alExpiration', title: 'AL Expiration', type: "date" },
        { field: 'glExpiration', title: 'GL Expiration', type: "date" },
        { field: 'wcExpiration', title: 'WC Expiration', type: "date" },
        { field: 'isSatisfied', title: 'Satisfied' },
        { title: 'Fiscal Year', field: 'fiscal' },
        { title: 'Company Name', field: 'company' },
        { title: 'DOT Number', field: 'dotNumber' },
        { title: 'Phone', field: 'phone' },
        { title: 'Email', field: 'email' },
        { title: 'Approved Date', field: 'approvedDate', type: 'date' },
        { title: 'Paid Date', field: 'paidDate', type: 'date' },
        { title: 'Sent to Risk', field: 'riskSent', type: 'date' },
        { title: 'Received from Risk', field: 'riskReceived', type: 'date' },
        { title: 'Created By', field: 'createdBy', type: 'text' },
        { title: 'Updated By', field: 'updatedBy', type: 'text' },
    ]

    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`

    const url = process.env.REACT_APP_KENDO_API + '/api/Reports/Permit' + tokenParam

    if (id)
        return <PermitView />

    return <KendoView grid='PERMIT_GRID' back='#' param='id' title='Permits' url={url} columns={columns} onClick={(id) => history.push('/permits/' + id)} />
}

function PermitView() {
    const [details, setDetails] = useState(null);
    const [access, setAccess] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const { id } = useParams();
    const role = authenticationService.getRole();

    useEffect(() => {
        GetPermit(id).then(response => {
            if (response.data != null)
                GetPermissions(2, response.data.status, role).then(resp => {
                    if (Array.isArray(resp.data)) {
                        setDetails(response.data);
                        setAccess(resp.data);
                    }
                })
        })
    }, [id, role, refresh])

    const handleStatus = (status) => {
        UpdateStatus(id, status).then(() => setRefresh(!refresh))
    }

    const actions = [
        { name: 'Details', icon: <DescriptionIcon />, module: 1, component: <Details /> },
        { name: 'Company', icon: <BusinessIcon />, module: 2, component: <Company /> },
        { name: 'Vehicles', icon: <CommuteIcon />, module: 3, component: <Vehicles /> },
        { name: 'Documents', icon: <FilesIcon />, module: 4, component: <Documents /> },
        { name: 'Insurance', icon: <InfoIcon />, module: 5, component: <Insurance /> },
        { name: 'Notes', icon: <CommentIcon />, module: 6, component: <NotesView module={2} entityId={id} /> },
        { name: 'Notices', icon: <NotesIcon />, module: 7, component: <NoticeView module={2} entityId={id} /> },
        { name: 'Attachments', icon: <AttachmentIcon />, module: 8, component: <AttachmentView module={2} entityId={id} /> },
        { name: 'Receipt', icon: <ReceiptIcon />, module: 9, component: <ReceiptView module={2} entityId={id} /> },
    ]
    /*Need to Change templates below. Make it One*/

    return <React.Fragment>
        <SpeedTemplate entityId={id} link='#permits' entity={details} access={access} actions={actions} />
        <StatusTemplate settings={access} handleClick={handleStatus}>
            <ModuleTemplate entity={details} settings={access} module={1} component={<Details />} />
            <ModuleTemplate entity={details} settings={access} module={2} component={<Company />} />
            <ModuleTemplate entity={details} settings={access} module={3} component={<Vehicles />} />
            <ModuleTemplate entity={details} settings={access} module={4} component={<Documents />} />
            <ModuleTemplate entity={details} settings={access} module={5} component={<Insurance />} />
            <ModuleTemplate entity={details} settings={access} module={6} component={<NotesView module={2} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={7} component={<NoticeView module={2} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={8} component={<AttachmentView module={2} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={9} component={<ReceiptView module={2} entityId={details ? details.vehicleId : null} />} />
        </StatusTemplate>

    </React.Fragment>
}

