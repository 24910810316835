import React, { useState, useEffect, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import DetailsView from './details';
import VehiclesView from './vehicles';
import SkuView from './sku';
import EventsView from './events';
import TotalView from './total';
import { GetAllItems, GetInvoice } from '../../REST/statement';
import TemplateView from './template';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


export default function Statement() {
    const { id } = useParams();

    if (id)
        return <StatementView />
    return <p>Page Not Available</p>

}

const StatementCharges = forwardRef(({ editable = true, items = [], display = true, handleSelect, handleRefresh, refresh, selected }, ref) => {
    return <React.Fragment>
        <VehiclesView editable={editable} charges={items} handleRefresh={handleRefresh} selected={selected} refresh={refresh} onSelect={handleSelect} />
        <EventsView editable={editable} charges={items} handleRefresh={handleRefresh} selected={selected} refresh={refresh} onSelect={handleSelect} />
        <SkuView editable={editable} charges={items} handleRefresh={handleRefresh} selected={selected} refresh={refresh} onSelect={handleSelect} />
    </React.Fragment>
})


function StatementView() {
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState([]);
    const [details, setDetails] = useState(null);
    const [refresh, setRefresh] = useState(false)
    const { id } = useParams();

    useEffect(() => {
        GetAllItems(id).then(response => {
            if (Array.isArray(response.data)) { setItems(response.data) }
        })
        GetInvoice(id).then(response => {
            if (response.data != null)
                setDetails(response.data);
        })
    }, [id, refresh])

    const handleRefresh = () => setRefresh(!refresh)

    const handleSelect = (item) => {
        const exist = selected.findIndex(e => e.module === item.module && e.entityId === item.entityId)
        if (exist < 0)
            setSelected(selected.concat([item]))
        else setSelected(selected.filter(e => e.module === item.module && e.entityId !== item.entityId))
    }

    return <React.Fragment>
        <DetailsView entity={details} />
        <StatementCharges editable={false} handleRefresh={handleRefresh} selected={selected} handleSelect={handleSelect} />
        <TotalView charges={items} handleRefresh={handleRefresh} selected={selected} refresh={refresh} />
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Invoice Report</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div className='col-12'>
                    {details && <TemplateView entity={details} />}
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    </React.Fragment>
}