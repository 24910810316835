import React, { Fragment, useState, useRef, useEffect } from 'react';
import KendoView from '../../components/crud/kendo';
import { FormView, Field } from '../../components/form/Index';
import { authenticationService } from '../../components/authorization';
import { GetDistinctPhysicalYears } from '../../REST/statement';

const config = [
    { field: 'invoiceNumber', title: 'Invoice Number', isGrid: true },
    { field: 'activityCode', title: 'Activity Code', isGrid: true },
    { field: 'functionCode', title: 'Function Code', isGrid: true },
    { field: 'revenueCode', title: 'Revenue Code', isGrid: true },
    { field: 'entityId', title: 'Event/Activity/Vehicle Id', isGrid: true },
    { field: 'amount', title: 'Amount Paid', type: 'amount', isGrid: true },
    { field: 'fiscalYear', title: 'Fiscal Year', type: 'number', isGrid: true },
    { field: 'paidDate', title: 'Payment Date', type: "datetime", isGrid: true },
]

export default function Daily() {
    const [year, setYear] = useState(new Date().getFullYear());
    const formView = useRef(null);
    const [open, setOpen] = useState(true)
    //const []

    const handleDateChange = (x) => {
        setYear(x);
        setOpen(false)
        setTimeout(() => {
            setOpen(true)
        }, 500)
    }
const CycleId = 0;
const EventIds = [];
const groupSimilar = false;

 const [distinctYears , setDistinctYears] = useState([]);
const [options, setOptions] = useState([]);

    const entity = {CycleId,  EventIds, groupSimilar};


    useEffect(() => {
        GetDistinctPhysicalYears(entity).then(resp => {
            console.log('respresprespresp', resp);
            let arr = [];
            
            if (resp.data) {
                 resp.data.map((e, i) => (
                     arr.push({label:e, value: e})
                ))
             setOptions(arr);
            }
        }).catch(ex => {
            console.log(ex)
        })
    }, [])



    
    // const getDistnctYears = (entity)=> {
    //     //console.log(entity)
    //     GetDistinctPhysicalYears(entity).then(response => {
    //         console.log('hiiiiii>>', response.data);
    //       setDistinctYears(response.data);
    //     }).catch(ex => { })
    //     // { label: '2019', value: 2019 },
    //     // { label: '2020', value: 2020 }
        
    // }
        // const options = [
        //    getDistnctYears(entity),
        //    console.log('distinct Years', distinctYears)
        //   ]
      

    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`

    return <Fragment>
        <FormView ref={formView} data={{ year }} >
            <Field.DropDown options={options} className='col-xs-12 col-sm-4 col-md-3' label='Select Year' changeEvt={handleDateChange} required field='year' />
        </FormView>
        {open && <KendoView grid='YEARLY_REPORT' param='id' style={{ height: 'calc(100%-100px)' }} title='Yearly Report' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Yearly' + tokenParam + '&year=' + year} columns={config} />}
    </Fragment>
}