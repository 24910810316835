import React from 'react';
import KendoView from '../../components/crud/kendo';
import { authenticationService } from '../../components/authorization';

const config = [
    { field: 'invoiceNumber', title: 'Invoice Number', isGrid: true },
    { field: 'name', title: 'Name', isGrid: true },
    { field: 'fiscalYear', title: 'Fiscal Year', isGrid: true },
    { field: 'finalizedDate', title: 'Finalized Date', type: 'date', isGrid: true },
    { field: 'invoiceDate', title: 'Invoice Date', type: 'date', isGrid: true },
    { field: 'dueDate', title: 'Due Date', type: 'date', isGrid: true },
    { field: 'paidDate', title: 'Payment Date', type: "datetime", isGrid: true },
]



export default function Temporary() {
    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`
    return <KendoView grid='TEMPORARY_REPORT' param='id' sort='invoiceDate' link='#' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Temporary' + tokenParam} columns={config} title='Temporary Invoices' />
}