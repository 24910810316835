import React from 'react';
import KendoView from '../../components/crud/kendo';
import { authenticationService } from '../../components/authorization';

const config = [
    { field: 'invoiceNumber', title: 'Invoice Number', isGrid: true },
    { field: 'name', title: 'Invoice Cycle', isGrid: true },
    { field: 'companyName', title: 'Company Name', isGrid: true },
    { field: 'dotnumber', title: 'DOT Number', isGrid: true },
    { field: 'fiscalYear', title: 'Fiscal Year', isGrid: true },
    { field: 'status', title: 'Status', isGrid: true },
    { field: 'invoiceDate', title: 'Invoice Date', type: "datetime", isGrid: true },
    { field: 'dueDate', title: 'Due Date', type: "datetime", isGrid: true },
    { field: 'paidDate', title: 'Payment Date', type: "datetime", isGrid: true },
    { field: 'finalizeDue', title: 'Finalize Due', type: "datetime", isGrid: true },
    { field: "createdOn", title: "CreatedOn", type: "datetime", isGrid: true },
    { field: "createdBy", title: "CreatedBy", isGrid: true },
    { field: 'amountDue', title: 'Amount Due', type: 'numeric', isGrid: true },
    { field: 'amountPaid', title: 'Amount Paid', type: 'numeric', isGrid: true },
    { field: 'total', title: 'Total Amount', type: 'numeric', isGrid: true }


]


export default function Invoice() {
    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`
    return <KendoView grid='INVOICE_REPORT' param='id' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Invoice' + tokenParam} columns={config} allowAdd={false} allowEdit={false} allowDelete={false} title='Invoice Report ' />
}