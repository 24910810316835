import React, { Fragment, useState, useRef } from 'react';
import KendoView from '../../components/crud/kendo';
import { FormView, Field } from '../../components/form/Index';
import moment from 'moment';
import { authenticationService } from '../../components/authorization';

const config = [
    { field: 'companyName', title: 'Company Name', isGrid: true },
    { field: 'vendorCode', title: 'Vendor Code', isGrid: true },
    { field: 'paymentDate', title: 'Payment Date', type: 'datetime', isGrid: true },
    { field: 'chargeDescription', title: 'Sku Details', isGrid: true },
    { field: 'invoiceNumber', title: 'Invoice Number', isGrid: true },
    { field: 'functionCode', title: 'Function Code', isGrid: true },
    { field: 'revenueCode', title: 'Revenue Code', isGrid: true },
    { field: 'entityId', title: 'Event/Activity/Vehicle Id', isGrid: true },
    { field: 'amount', title: 'Amount Paid', type: 'amount', isGrid: true }
]

export default function Daily() {
    const [date, setDate] = useState(new Date().toISOString());
    const formView = useRef(null);
    const [open, setOpen] = useState(true)

    const handleDateChange = (x) => {
        setDate(moment(x).format('YYYY-MM-DD'))
        setOpen(false)
        setTimeout(() => {
            setOpen(true)
        }, 500)
    }

    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`

    return <Fragment>
        <FormView ref={formView} data={{ date }} >
            <Field.DatePicker className='col-xs-12 col-sm-4 col-md-3' label='Select Date' changeEvt={handleDateChange} required field='date' />
        </FormView>
        {open && date && <KendoView grid='DAILY_REPORT' param='id' style={{ height: 'calc(100%-100px)' }} title='Daily Report' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Date' + tokenParam + '&dt=' + date} columns={config} />}
    </Fragment>
}