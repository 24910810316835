import React, { useState, useRef, useEffect, Fragment } from 'react';
import { FormView, Field } from '../../components/form/Index';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import { RecordPayment, GetAllInvoice, ConnectPayment, ConnectBulkPayment } from '../../REST/statement';

export default function RecordReceipt({ onBack }) {
    const { id } = useParams();
    const [data, setData] = useState({});
    const formView = useRef(null)
    const [complete, setComplete] = useState(false);

    const handleChange = () => {
        let result = formView.current.getCurrentResult();
        if (result) {
            setData({ ...data, ...result })
        }
    }

    const handleSubmit = () => {
        let result = formView.current.getResult();
        if (result) {
            if (isEqual(result.paymentType, 2)) {
                result.accountNumber = 'GCN_INTERNAL_COLLECT';
                result.confirmationNumber = 'MANUAL'
            }
            result.invoiceId = id;
            RecordPayment(result).then(response => {
                setComplete(true);
            })
        }
    }

    const options = [
        { label: 'Online Payment', value: 1 },
        { label: 'Cash', value: 2 },
        { label: 'Check', value: 3 }
    ]



    if (complete)
        return <h3>Payment Complete. Please Close Browser Window</h3>

    return <div className='row'>
        <div className='col-6'>
            <FormView ref={formView} data={data}>
                <Field.TextBox field='accountName' label='Name' required />
                <Field.DatePicker field='paymentDate' label='Date Paid' required />
                <Field.DropDown unordered field='paymentType' label='Payment Type' options={options} required changeEvt={handleChange} />
                {isEqual(data.paymentType, 3) && <Field.TextBox field='accountNumber' label='Check Number' required />}
                {isEqual(data.paymentType, 1) && <Field.TextBox field='confirmationNum' label='Confirmation Number' required />}
                <div className='row'>
                    <div className='col-6'>
                        <Button onClick={handleSubmit} fullWidth variant='outlined'>Submit Payment</Button>
                    </div>
                    <div className='col-6'>
                        <Button onClick={onBack} fullWidth variant='outlined'>Cancel</Button>
                    </div>
                </div>
            </FormView>
        </div>
    </div>
}

export const isEqual = (l, r) => {
    if (l && r)
        return l === r || l.toString() === r.toString()
    return false
}

export const PaymentReceipt = () => {

    const [list, setList] = useState([]);
    const formView = useRef(null)

    useEffect(() => {
        GetAllInvoice().then(resp => {
            setList(resp.data.filter(e => e.statusValue === 99).map(e => {
                return { label: `${e.name} - ${e.id}`, value: e.id }
            }))
        })
    }, [])

    const handleClick = () => {
        const data = formView.current.getResult();
        if (data) {
            ConnectPayment({ ...data, paymentType: 1 }).then(resp => {
                console.log(resp.data);
            })
        }
    }

    const handleFile = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader()
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const arr = csvToArray(bstr)
            const result = arr.map(e => {
                return {invoiceId: e.Id, accountName: e.FirstName + " " + e.LastName, accountDetails: e.AccountDetails, confirmationNumber: e.ConfirmationNum, paymentDate: e.PaymentDate, paymentType: e.PaymentType }
            }).filter(e => e.invoiceId !== undefined)
            ConnectBulkPayment(result).then(resp => {
                console.log(resp.data);
            })
            //console.log(result)
        }
        reader.readAsBinaryString(file);
    }

    

    return <Fragment>
        <FormView ref={formView} data={{}}>
            <Field.DropDown field='invoiceId' options={list} label='Invoice Number' />
            <Field.TextBox field='accountName' label='Account Name' required />
            <Field.TextBox field='accountDetails' label='Account Details' required />
            <Field.TextBox field='confirmationNumber' label='Confirmation Number' required />
            <Field.DatePicker field='paymentDate' label='Payment Date' required />
            <Field.Content>
                <Button variant='outlined' onClick={handleClick}>Submit</Button>
            </Field.Content>
        </FormView>
        <hr />
        <h3 className='text-center'>OR</h3>
        <hr />
        <h4 className='lead'>Upload CSV</h4>
        <h6>Format</h6>
        <pre><small>AccountNum,MessageType,PartnerKey,FirstName,LastName,PaymentDate,PaymentAmount,FeeAmount,ConfirmationNum,PaymentChannel,PaymentType,AccountDetails,ActivityType,Module,Id</small></pre>
        <div className='row'>
            <div className='col-12'>
                <input type='file' onChange={handleFile} />
            </div>
        </div>
    </Fragment>
}

const csvToArray = (str, delimiter = ",") => {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter).map(e => e.trim());

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        const el = headers.reduce(function (object, header, index) {
            object[header] = values[index];
            return object;
        }, {});
        return el;
    });

    // return the array
    return arr;
}