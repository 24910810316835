import decode from 'jsonwebtoken/decode';

export const authenticationService = {
    user: () => {
        const verify = decode((localStorage.getItem('token') || '').replace('Bearer ', ''));
        if (!verify) return null
        return localStorage.getItem('user') || null
    },
    setToken: (token, username) => {
        localStorage.setItem('token', token)
        localStorage.setItem('user', username)
    },
    getToken: () => {
        return localStorage.getItem('token')
    },
    removeToken: () => {
        localStorage.clear();
    },
    getUserInfo: () => {
        const verify = decode((localStorage.getItem('token') || '').replace('Bearer ', ''));
        if (verify) {
            return { firstName: verify.given_name, lastName: verify.family_name, email: verify.email }
        }
        return null;
    },
    getRole: () => {
        const verify = decode((localStorage.getItem('token') || '').replace('Bearer ', ''));
        if (verify) {
            return verify.nameid;
        }
        return null;
    },
    isAccess: (module) => {
        const verify = decode((localStorage.getItem('token') || '').replace('Bearer ', ''));
        if (verify) {
            const [access, id] = verify.certserialnumber;
            if (access && id) {
                return access[module - 1] === '1'
            }
        }
        return false;
    },
    isAdmin: () => {
        const verify = decode((localStorage.getItem('token') || '').replace('Bearer ', ''));
        if (verify) {
            const ROLES = process.env.REACT_APP_ADMIN_ROLES
            return ROLES.split(',').includes(verify.role)
        }
        return false;
    },
    getRefreshToken: () => {
        return localStorage.getItem('refreshToken');
    },
    setTokens: (name, token) => {
        localStorage.setItem(name, token);
    }
}