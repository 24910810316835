import Axios from './base';

export const GetNotice = (id) => Axios().get('Notice/' + id)
export const AddNotice = (entity) => Axios('Add Success').post('Notice', entity)
export const UpdateNotice = (entity) => Axios('Update Success').put('Notice', entity)
export const GetNoticeOptions = () => Axios().get('Notice/Options')
export const GetNoticeData = (module, id) => Axios().get('Notice/Values/' + module + '/' + id)

export const GetContent = (id) => Axios().get('Content/' + id);
export const AddContent = (entity) => Axios('Add Success').post('Content', entity);
export const UpdateContent = (entity) => Axios('Update Success').put('Content', entity);
export const GetContentOptions = () => Axios().get('Content/Options');

export const GetRoles = () => Axios().get('User/Options')

export const GetCycles = () => Axios().get('Cycle')
export const GetCycle = (id) => Axios().get('Cycle/' + id)
export const AddCycle = (entity) => Axios('Save Success').post('Cycle', entity)
export const UpdateCycle = (entity) => Axios('Update Success').put('Cycle', entity)
export const RunInvoice = (id) => Axios('Invoices Generated').get('Statement/Transient/' + id)

export const GetSku = (id) => Axios().get('Sku/' + id)
export const AddSku = (entity) => Axios('Sku Added').post('Sku', entity)
export const UpdateSku = (entity) => Axios('Sku Updated').put('Sku', entity)

export const GetPermits = () => Axios().get('PermitType')
export const GetCameras = () => Axios().get('Camera')
export const GetPricing = () => Axios().get('CameraPricing')

export const GetFields = () => Axios().get('Schedule/Options')
export const GetSchedule = (id) => Axios().get('Schedule/' + id)
export const AddSchedule = (entity) => Axios('Added Successfully').post('Schedule', entity)
export const UpdateSchedule = (entity) => Axios('Updated Successfully').put('Schedule', entity)

export const GetTemplateFields = () => Axios().get('Notification/Options')
export const GetNotification = (id) => Axios().get('Notification/' + id)
export const AddNotification = (entity) => Axios('Added Successfully').post('Notification', entity)
export const UpdateNotification = (entity) => Axios('Updated Successfully').put('Notification', entity)

export const GetGridConfig = (id) => Axios().get('GridConfig/' + id)
export const SaveGridConfig = (entity) => Axios().post('GridConfig', entity)
