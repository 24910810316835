import React, { useImperativeHandle, forwardRef } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import CRUDView from '../../components/crud';
import ArrowIcon from '@material-ui/icons/ArrowRightOutlined';

function Permits({ editable = false, display = true }, ref) {

    const { id } = useParams();

    useImperativeHandle(ref, () => ({
        validate: () => {
            return Promise.resolve()
        },
        submit: () => {
            return Promise.resolve()
        }
    }))

    const history = useHistory();

    const columns = [
        { title: 'Permit Id', field: 'id', isGrid: true, type: 'text' },
        { title: 'Application Type', field: 'name', isGrid: true, type: 'text' },
        { title: 'Plate Number', field: 'plateNumber', isGrid: true, type: 'text' },
        { title: 'Plate Region', field: 'plateRegion', isGrid: true, type: 'text' },
        { title: 'Status', field: 'status', isGrid: true, type: 'text' },
        { title: 'Expiry Date', field: 'expiryDate', isGrid: true, type: 'date' },
        { title: 'Fiscal Year', field: 'fiscal', isGrid: true, type: 'text' },
        { title: 'Company Name', field: 'company', isGrid: true, type: 'text' },
        { title: 'DOT Number', field: 'dotnumber', isGrid: true, type: 'text' },
        { title: 'Phone', field: 'phone', isGrid: true, type: 'text' },
        { title: 'Email', field: 'email', isGrid: true, type: 'text' },
        { title: 'Approved Date', field: 'approvedDate', isGrid: true, type: 'date' },
        { title: 'Paid Date', field: 'paidDate', isGrid: true, type: 'date' },
        { title: 'Sent to Risk', field: 'riskSent', isGrid: true, type: 'text' },
        { title: 'Received from Risk', field: 'riskReceived', isGrid: true, type: 'date' },
        { title: 'Created By', field: 'createdBy', isGrid: true, type: 'text' },
        { title: 'Updated By', field: 'updatedBy', isGrid: true, type: 'text' },
    ]

    const actions = [
        { icon: ArrowIcon, tooltip: 'View Permit', onClick: (event, rowData) => history.push('/permits/' + rowData.id) }
    ]

    return <React.Fragment>
        <CRUDView actions={actions}
            url={process.env.REACT_APP_API_URL + '/Permit/Company/' + id} id='id' columns={columns} title={'Permits'} allowAdd={editable} allowDelete={editable} allowEdit={editable} pageCount={5} />
    </React.Fragment>
}

export default forwardRef(Permits);

