import React, { useEffect, useState, useRef } from 'react';
import CRUDView from '../../../components/crud';
import { useParams, useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { FormView, Field } from '../../../components/form/Index';
import { GetNotification, AddNotification, UpdateNotification, GetTemplateFields } from '../../../REST/administration';
import Button from '@material-ui/core/Button';

const modules = [
    { label: 'Applications', value: 1 },
    { label: 'Permits', value: 2 },
    { label: 'Company', value: 3 },
    { label: 'Invoices', value: 4 },
    { label: 'Events', value: 5 },
]

const types = [
    { label: 'Created', value: 1 },
    { label: 'Status Change', value: 2 },
    { label: 'Field Value Change', value: 3 },
    { label: 'Attachments Change', value: 4 },
    { label: 'Notices Change', value: 5 },
    { label: 'Notes Change', value: 6 },
]

function Notifications() {

    const { id } = useParams();
    const history = useHistory();

    const config = [
        { field: 'name', title: 'Name ', type: 'text', required: true, isGrid: true },
        { field: 'description', title: 'Description', required: true, isGrid: true }
    ]

    const actions = [
        { icon: AddIcon, isFreeAction: true, tooltip: 'Add Record', onClick: () => { history.push('/admin/notifications/0') } },
        { icon: EditIcon, tooltip: 'Edit Record', onClick: (evt, rowData) => { history.push('/admin/notifications/' + rowData.id) } }
    ]

    if (id !== null && id !== undefined)
        return <DetailsView />

    return <CRUDView link='#admin' url={process.env.REACT_APP_API_URL + '/Notification'}
        allowEdit={false}
        allowAdd={false}
        allowDelete={true}
        actions={actions}
        columns={config} title='Notifications' id='id' />
}

export default Notifications;

function DetailsView() {
    const formView = useRef(null);
    const [data, setData] = useState(null);
    const [fields, setFields] = useState([]);
    const [opts, setOpts] = useState([]);
    const { id } = useParams();
    const [refresh, setRefresh] = useState(false);
    const [options, setOptions] = useState({ emails: [], status: [], fields: [], entityFields: [] })
    const history = useHistory()

    useEffect(() => {
        GetTemplateFields().then(resp => setOptions(resp.data))
    }, [])

    useEffect(() => {
        if (id !== 0 && id !== '0')
            GetNotification(id).then(resp => {
                setData(resp.data);
            })
    }, [id, refresh])

    useEffect(() => {
        setOpts(getOptions(data))
        setFields(getFields(data))
        //  eslint-disable-next-line
    }, [data, options])

    const handleChange = () => {
        let result = formView.current.getCurrentResult();
        const x = { ...data, ...result, eventDetails: '', recepients: '' }
        setData(x)
        setOpts(getOptions(x))
        setFields(getFields(x))
    }

    const handleSave = () => {
        let result = formView.current.getResult();
        if (result) {
            if (id !== 0 && id !== '0')
                UpdateNotification({ ...data, ...result }).then(resp => setRefresh(!refresh))
            else
                AddNotification(result).then(resp => history.push('/admin/notifications/' + resp.data.id))
        }
    }

    const getOptions = (x) => {
        if (x && x.module) {
            console.log(x);
            if (String(x.eventOn) === '2') {
                const idx = parseInt(x.module);
                return options.status[idx - 1] || [];
            }
            if (String(x.eventOn) === '3') {
                const idx = parseInt(x.module);
                try {
                    return options.entityFields[idx - 1]
                }
                catch (ex) { return [] }
            }
            return []
        }
        return []
    }

    const getFields = (x) => {
        if (x && x.module) {
            const idx = parseInt(x.module);
            if (options.fields.length >= idx)
                return [options.fields[idx - 1]];
        }
        return []
    }

    return <div className='row'><div className='col-sm-6'><FormView ref={formView} data={data}>
        <Field.TextBox label='Name' field='name' required />
        <Field.TextArea label='Description' field='description' required />
        <Field.DropDown changeEvt={handleChange} label='Module' field='module' options={modules} required />
        <Field.DropDown changeEvt={handleChange} label='Event On' field='eventOn' options={types} required />
        {data && String(data.eventOn) === '2' && <Field.MultiSelect string label='Event Details' field='eventDetails' options={opts} required />}
        {data && String(data.eventOn) === '3' && <Field.MultiSelect string label='Event Details' field='eventDetails' options={opts} required />}
        <Field.MultiSelect string changeEvt={handleChange} label='Recepients' field='recepients' options={options.emails} required />
        <Field.TextBox label='Email Subject' field='subject' required />
        <Field.CheckBox label='Notify External User' field='allowExternal' />
        <div className='row'>
            <Field.DocumentEditor className='col' label='Internal Template' field='template1' fields={Array.isArray(fields) ? fields : []} />
            <Field.DocumentEditor className='col' label='External Template' field='template2' fields={Array.isArray(fields) ? fields : []} />
        </div>
        <div className='row'>
            <div className='col-6'>
                <Button fullWidth variant='outlined' onClick={handleSave} color='secondary'>Submit</Button>
            </div>
            <div className='col-6'>
                <Button fullWidth href='#/admin/notifications' variant='outlined' color='secondary'>Back</Button>
            </div>
        </div>
    </FormView></div></div>
}