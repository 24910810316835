import React, { useImperativeHandle, forwardRef } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import CRUDView from '../../components/crud';
import ArrowIcon from '@material-ui/icons/ArrowRightOutlined';

function Events({ editable = false, display = true }, ref) {

    const { id } = useParams();

    useImperativeHandle(ref, () => ({
        validate: () => {
            return Promise.resolve()
        },
        submit: () => {
            return Promise.resolve()
        }
    }))

    const history = useHistory();


    const columns =  [
        { field: 'id', title: 'Event  435 Id', isGrid: true, type: 'text' },
        { field: 'camera', title: 'Camera Name', isGrid: true , type: 'text'},
        { field: 'activity', title: 'Carrier Type', isGrid: true, type: 'text' },
        { field: 'transportType', title: 'Activity Type', isGrid: true , type: 'text'},
        { field: 'plateNumber', title: 'License Plate / Tail Number', isGrid: true , type: 'text'},
        { field: 'plateRegion', title: 'Region', isGrid: true, type: 'text' },
        { field: 'dotnumber', title: 'DOT', isGrid: true , type: 'text'},
        { field: 'company', title: 'Company Name', isGrid: true },
        { field: 'activityTime', title: 'Activity Date', type: 'datetime', isGrid: true },
        { field: 'status', title: 'Status', isGrid: true , type: 'text'}              
    ]

    const actions = [
        { icon: ArrowIcon, tooltip: 'View Event', onClick: (event, rowData) => history.push('/events/' + rowData.id) }
    ]

    return <React.Fragment>
        <CRUDView actions={actions}
            url={process.env.REACT_APP_API_URL + '/Company/Events/' + id} id='id' columns={columns} title={'Events'}  allowDelete={false} allowEdit={false}/>
    </React.Fragment>
}

export default forwardRef(Events);

