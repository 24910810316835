import React, { forwardRef, useState, useEffect, createRef } from 'react';
import { useParams } from 'react-router-dom';
import { GetItems } from '../../REST/statement';
import { UpdateEventCharge } from '../../REST/cameraEvents';
import { SimpleCheckBox } from '../../components/checkbox';
import { simpleDateTime } from '../../components/dates';
import { FormView, Field } from '../../components/form/Index';
import { useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';

function Events({ editable = true, display = true, charges = [], selected = [], refresh, onSelect }, ref) {

    const [vehicles, setVehicles] = useState([]);
    const { id } = useParams();
    const formView = useRef(Array(1000).map(()=> createRef()));
   
    useEffect(() => {
        GetItems(id, 4).then(resp => {
            if (Array.isArray(resp.data))
                setVehicles(resp.data)
        })
    }, [id, refresh])

 


    const handleCheck = (entityId) => {
        onSelect({ module: 4, entityId })
    }
    const handleSave = (idx,event) =>() =>{
        
        try{
            console.log(formView.current[idx].getResult());
            console.log(event)
            let result = formView.current[idx].getResult();
            UpdateEventCharge(event.id, result.charge).then(() => refresh)
           // UpdateEventCharge()
        }
        catch(ex){

        }

    }

    return <React.Fragment>
        {vehicles.length > 0 && (
            <React.Fragment>
                <div className='row'>
                    <div className='col-10'>
                        <small>Activity Details</small>
                    </div>
                    <div className='col-2 text-right'>
                        <small>Amount</small>
                    </div>
                </div>

                {
                    vehicles.map((e, i) => {
                        return <div key={i} className='row border-top border-bottom pt-3'>
                            <div className='col-6'>
                                <SimpleCheckBox onCheck={() => handleCheck(e.id)} editable={editable && !e.isPaid} checked={selected.findIndex(x => x.module === 4 && x.entityId === e.id) >= 0}>
                                    <p className='w-100 h6'><small className='text-muted'>Activity: </small>{e.activity}</p>
                                    <p className='w-100 h6'><small className='text-muted'>Activity Time: </small> {simpleDateTime(e.activityTime)}</p>
                                    <p className='w-100 h6'><small className='text-muted'>Camera Name: </small> {e.cameraName}</p>
                                    <p className='w-100 h6'><small className='text-muted'>Plate Number: </small>{e.plateNumber}</p>
                                    <p className='w-100 h6'><small className='text-muted'>Plate Region: </small>{e.plateRegion}</p>
                                    <p className='w-100 h6'><small className='text-muted'>Event ID: </small>{e.id}</p>
                                </SimpleCheckBox>
                            </div>
                            <div className='col-2 text-right my-auto'>
                                {e.isPaid && <b>Paid</b>}
                            </div>
                            <div className='col-4 text-right my-auto'>
                                 <FormView ref={x => {formView.current[i] = x}} data={e} disabled={!editable || e.isPaid}>
                                    <div className='row'>
                                    <Field.AmountText className='col-8'  number label='Activity Charge' field='charge' required />
                                    <div className='col-4'><IconButton  onClick={handleSave(i,e)}><SaveIcon /></IconButton></div>
                                    </div>
                                    
                               </FormView>
                              
                            </div>
                        </div>
                    })
                }
            </React.Fragment>
        )}
    </React.Fragment>
}

export default forwardRef(Events);

// Payment Reverse 