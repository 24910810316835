import React from 'react';
import KendoView from '../../components/crud/kendo';
import { authenticationService } from '../../components/authorization';

const config = [

    { field: 'name', title: 'Company Name', type: 'text', isGrid: true },
    { field: 'email', title: 'Company Email', type: 'text', required: true, isGrid: true },
    { field: 'vendorCode', title: 'Vendor Code', type: 'textarea', isGrid: true },
    { field: 'invoiceNumber', title: 'Invoice Number', type: 'text', required: true, isGrid: true },
    { field: 'invoiceDate', title: 'Invoice Date', type: 'date', isGrid: true },
    { field: 'day30', title: '0-30 Days', type: 'amount', isGrid: true },
    { field: 'day60', title: '30-60 Days', type: 'amount', required: true, isGrid: true },
    { field: 'day90', title: '60-90 Days', type: 'amount', isGrid: true },
    { field: 'day120', title: '90-120 Days', type: 'amount', required: true, isGrid: true },
    { field: 'over120', title: 'Over 120 Days', type: 'amount', required: true, isGrid: true },
    { field: 'balance', title: 'Balance', type: 'amount', isGrid: true }
]

export default function Ageing() {
    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`
    
    return <KendoView grid='INVOICE_GRID' link='#' sort='invoiceDate' param='id' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Ageing' + tokenParam} columns={config} title='Aging Report ' />
}