import React from 'react';
import CRUDView from '../../../components/crud';
import { GetRoles } from '../../../REST/administration';
import { useEffect } from 'react';
import { useState } from 'react';

export default function Users() {
    const [roles, setRoles] = useState([])
    useEffect(() => {
        GetRoles().then(resp => {
            const hideRoles = process.env.REACT_APP_HIDE_ROLE
            const roleFilter = hideRoles ? hideRoles.split(',') : []
            if (Array.isArray(resp.data))
                setRoles(resp.data.filter(e => !roleFilter.includes(String(e.value))))
        })
    }, [])

    const config = [
        { field: 'userName', title: 'User Name', type: 'text', isGrid: true },
        { field: 'firstName', title: 'First Name', type: 'textarea', isGrid: true },
        { field: 'lastName', title: 'Last Name', type: 'text', required: true, isGrid: true },
        { field: 'title', title: 'Title', type: 'text', required: true, isGrid: true },
        { field: 'department', title: 'Department', type: 'text', required: true, isGrid: true },
        { field: 'email', title: 'Email', type: 'text', required: true, isGrid: true },
        { field: 'phone', title: 'Phone', type: 'text', required: true, isGrid: true },
        { field: 'roleId', title: 'User Role', type: 'select', required: true, isGrid: true, options: roles }
    ]

    return <CRUDView link='#admin' url={`${process.env.REACT_APP_API_URL}/User`} columns={config} title='Users' allowDelete={false} id='id' />
}

