import React, { useImperativeHandle, forwardRef } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import { GetInvoice } from '../../REST/application';
import Button from '@material-ui/core/Button';

function Invoice({ editable = true, display = true }, ref) {

    const { id } = useParams();
    const history = useHistory();

    useImperativeHandle(ref, () => ({
        validate: () => {
            return Promise.resolve();
        },
        submit: () => {
            return Promise.resolve()
        }
    }))

    const handlePayment = () => {
        GetInvoice(id).then(response => {
            history.push('/invoices/' + response.data)
        })
    }

    return <React.Fragment>
        <Button className='float-right' onClick={handlePayment} variant='outlined'>View Invoice</Button>
    </React.Fragment>
}

export default forwardRef(Invoice);