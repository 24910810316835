import React from 'react';
import PrivateRoute from '../Authentication/private';
import { AdminDashboard } from '../Dashboard';
import Users from './Users';
import Notices from './Notices';
import Content from './Messages';
import PermitTypes from './PermitTypes';
import Cameras from './Cameras';
import Cycles from './Cycles';
import Skus from './Skus';
import Schedules from './Schedules';
import Notifications from './Notifications';
import AttachmentTypes from './AttachmentTypes';
import Transient from './Transient';
import CameraPricing from './CameraPricing';
import CameraPricingDetails from './CameraPricingDetails';

function Admin() {
    return (
        <React.Fragment>
            <PrivateRoute exact path='/admin' component={AdminDashboard} />
            <PrivateRoute exact path='/admin/users/:id?' component={Users} />
            <PrivateRoute exact path='/admin/attachments/:id?' component={AttachmentTypes} />
            <PrivateRoute exact path='/admin/notices/:id?' component={Notices} />
            <PrivateRoute exact path='/admin/content/:id?' component={Content} />
            <PrivateRoute exact path='/admin/permits/:id?' component={PermitTypes} />
            <PrivateRoute exact path='/admin/cameras/:id?' component={Cameras} />
            <PrivateRoute exact path='/admin/cycles/:id?' component={Cycles} />
            <PrivateRoute exact path='/admin/skus/:id?' component={Skus} />
            <PrivateRoute exact path='/admin/schedules/:id?' component={Schedules} />
            <PrivateRoute exact path='/admin/notifications/:id?' component={Notifications} />
            <PrivateRoute exact path='/admin/transient/:id?' component={Transient} />
            <PrivateRoute exact path='/admin/camerapricing/:id?' component={CameraPricing} />
            <PrivateRoute exact path='/admin/pricingdetails/:pricingId?' component={CameraPricingDetails} />
        </React.Fragment>
    );
}

export default Admin;