import React from 'react';
import { FormView, Field } from '../../components/form/Index';
import { useState, useRef } from 'react';
import { SendEventEmail } from '../../REST/cameraEvents';
import moment from 'moment';


import Button from '@material-ui/core/Button';


import '../../EventsTemplate.css';
import { useEffect } from 'react';

export default function EmailTemplate(props) {
    const [email, setEmail] = useState(null);
    const [subject, setSubject] = useState(null);
    const [body, setBody] = useState(null);
    const formView = useRef(null);
    const [data,setData] = useState([]);
   
   
console.log('email data',data);
useEffect(()=>{
    props.frameView.current.contentWindow.postMessage({ message: 'get_selected_data' }, "*");
    const messageHandler = (message) => {
       if (message.data.message == 'get_selected_data') {
            console.log('getselecteddata emailtemplate',message.data.selectedImageRow)
            setData(message.data.selectedImageRow)
            // setSelectedRows(message.data.selectedImageRow);
            // setShowPopUp(true);
        }
      

    }
    window.addEventListener('message', messageHandler)
    return () => {
        window.removeEventListener('message', messageHandler);
    }
},[])

const handleEmail = () => {
    let result = formView.current.getResult();
    if (result) {
        let html = document.getElementById('events_temp').innerHTML;
        SendEventEmail({
            html: `<!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style>
                    
                    table {
                        border-collapse: collapse;
                        width: 100%;
                        page-break-inside:auto
                    }
                    tr    { page-break-inside:avoid; page-break-after:auto }
                    
                    .maintable td {
                        border: 1px solid #d2d2d2;
                        padding: 10px;
                        text-align: left;
                        
                    }
                    .content-column   td
                    {
                        border:none;
                        border-bottom: 1px solid #C2C2C2;
                    }
                    .content-column tr:last-child td{
                        border:none;
                    }
                    th, td {
                        padding: 8px;
                        text-align: left;
                    }
                    .image-column {
                        width: 40%;
                    }
                    .content-column {
                        width: 60%;
                    }
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                    
                </style>
            </head>
            <body>${html}</body></html>`,
            email: result?.email,
            body:  result?.body,
            subject:  result?.subject
        }).then(response => {
            console.log('response--->', response);
            setEmail('');
            setSubject('');
            setBody('')
            window.location.reload();
     //props.frameView.current.contentWindow.postMessage({ message: 'refresh_grid' }, "*")
        })

        //EmailReport({ email: result.email, html, invoice: { ...entity, invoiceurl: (process.env.REACT_APP_PROD_URL + '/#/statement/' + entity.id) } })
    }
}
return (<div>
    <div className='col-sm-4 col-xs-12'>
            <FormView ref={formView} data={{ email }}>
                <Field.TextBox label='Email' field='email' required />
                <Field.TextBox label='Subject' field='subject' />
                <Field.TextArea label='Body' field='body'  />
                <Button onClick={handleEmail}>Send Email</Button>
            </FormView>
        </div>
        {/* <div style={{ width: '1024px', margin: '0 auto' }}> */}
        <div id='events_temp' className='col-sm-10 col-xs-12' style={{marginTop: '2%', marginBottom: '2%'}} >
    {data?.map((obj) => {
           return <div>
            <table class="maintable" >
                    <tr>
                        <td class="image-column">
                            <img src={`https://gcn-camera.s3.amazonaws.com/${obj['image_Uid']}_context.jpg`} alt="Image"/>
                        </td>
                        <td class="content-column">
                            <table>
                            
                                <tr>
                                    <td> <span style={{color: '#304E6A', fontWeight: 'bold'}}>Grand Canyon N.P. Airport</span></td>
                                </tr>
                                <tr>
                                    <td> <span style={{color: '#304E6A', fontWeight: 'bold'}}>Event id : </span> {obj['id']}  </td>
                                </tr>
                                <tr>
                                    <td> <span style={{color: '#304E6A', fontWeight: 'bold'}}> License Plate : </span> {obj['plate_Number']}  &nbsp;&nbsp;&nbsp;&nbsp; <img src={`https://gcn-camera.s3.amazonaws.com/${obj['image_Uid']}_plate.jpg`} alt="Image" style={{height:'35px', width: '110px'}}/></td>
                                </tr>
                                <tr>
                                    <td> <span style={{color: '#304E6A', fontWeight: 'bold'}}>Activity Date :</span>  {moment(obj['activity_Time']).format('MM/DD/YYYY hh:mm A')}</td>
                                </tr>
                                <tr>
                                    <td> <span style={{color: '#304E6A', fontWeight: 'bold'}}> Camera Name :</span> {obj['cameraName']}</td>
                                </tr>
                                <tr>
                                    <td> <span style={{color: '#304E6A', fontWeight: 'bold'}}> Status :</span> {obj['statusName']}</td>
                                </tr>
                                <tr>
                                  { obj['name']== "TRANSIENT" ?  <td> <span style={{color: '#304E6A', fontWeight: 'bold'}}>Company Name :</span> {obj['name']}</td> : 
                                  <td> <span style={{color: '#304E6A', fontWeight: 'bold'}}>Company Name/Dot Number : </span>{obj['name']} &nbsp; | &nbsp; {obj['dotNumber']} </td> }
                                </tr>
                            </table>
                        </td>
                    </tr>
                    </table>
                    </div>

}

)

}
{/* </div> */}
</div>
</div>);

}