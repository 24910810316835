import Axios from './base';
//import axios from 'axios';

const URL = process.env.REACT_APP_API_URL + '/Authorize'

export const Authorize = (username, password) => {
    return Axios('Welcome ' + username, 'Invalid Username/Password').post(`${URL}/Token`, { username, password })
}

export const Refresh = (entity) => {
    return Axios(null, 'Token Expired').post(`${URL}/Refresh`, entity)
}

export const GetUser = (username) => {
    return Axios().get('Authorize/' + username)
}

export const ValidateToken = (id) => Axios().get('Authorize/Validate/' + id);

export const ResetPassword = (id, entity) => Axios('Password Saved !').post('Authorize/Reset/' + id, entity);

export const ForgotPassword = (entity) => Axios('Password sent to email address successfully !').post('Authorize/ForgotPassword', entity);

export const ForgotUsername = (entity) => Axios('Username sent to email address successfully !').post('Authorize/ForgotUsername', entity);

export const UpdateMyAccount = (username, entity) => Axios('Account details updated successfully !').put('Authorize/UpdateMyAccount/' + username, entity);
