import Axios from 'axios';
import { authenticationService } from '../components/authorization';
import DefaultSettings from '../components/settings';

const URL = process.env.REACT_APP_API_URL

export default function Base(done, fail) {
    const instance = Axios.create({
        baseURL: URL,
        headers: {
            'Authorization': 'Bearer ' + authenticationService.getToken()
        }
    })

    instance.interceptors.request.use(function (config) {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().add();
        return config;
    }, function (error) {
        return Promise.reject(error);
    })

    instance.interceptors.response.use(function (response) {
        if (DefaultSettings.getLoader() != null)
            DefaultSettings.getLoader().remove();
        if (DefaultSettings.getAlert() != null && done !== null && done !== undefined)
            DefaultSettings.getAlert().show(done, 'success');
        return response
    }, function (error) {
        const _originalRequest = error.config;
        if (error.response.status === 401) {
            if (error.response.headers.pragma === 'Token-Expired' && !_originalRequest._retry) {
                _originalRequest._retry = true;
                return refresh(authenticationService.getToken(), authenticationService.getRefreshToken()).then(refreshResponse => {
                    var _response = refreshResponse.data;
                    authenticationService.setTokens('token', _response.token);
                    authenticationService.setTokens('refreshToken', _response.refreshToken);
                    _originalRequest.headers.Authorization = 'Bearer ' + _response.token;
                    if (DefaultSettings.getLoader() != null)
                        DefaultSettings.getLoader().remove();
                    return Axios(_originalRequest);
                }).catch(err => {
                    localStorage.clear()// to clear the
                    if (DefaultSettings.getLoader() != null)
                        DefaultSettings.getLoader().remove();
                    return Promise.reject(err);
                });
            }
            else {
                localStorage.clear()
            }
        }
        else {
            if (DefaultSettings.getLoader() != null)
                DefaultSettings.getLoader().remove();
            if (DefaultSettings.getAlert() != null)
                DefaultSettings.getAlert().show(fail || 'Error Occurred', 'error');
            return Promise.reject(error);
        }
    });

    return instance;
}

async function refresh(token, refreshToken) {
    return Axios.post(URL + '/Authorize/Refresh', { token, refreshToken })
}