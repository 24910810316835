import React from 'react';
import { useParams } from 'react-router-dom';
import CRUDView from '../../../components/crud';

export default function CameraPricingDetails() {

    const params = useParams()

    const config = [
        { field: 'price', title: 'Price', type: 'amount', isGrid: true },
        { field: 'startTime', title: 'Start Time (in minutes)', type: 'text', isGrid: true },
        { field: 'endTime', title: 'End Time (in minutes)', type: 'text', isGrid: true },
    ]

    return <CRUDView link='#admin/camerapricing' url={`${process.env.REACT_APP_API_URL}/camerapricingdetails/${params.pricingId}`} columns={config} title='Camera Pricing Details'
        allowDelete={false} id='id' />
}
