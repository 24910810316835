import React from 'react';
import Paper from '@material-ui/core/Paper';
import { simpleDate } from '../../components/dates';
import { GetAllTotals } from '../../REST/statement';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import ReceiptView from '../Widgets/Receipt';
import { Button } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

function ReceiptsView({ editable = true, display = true, entity }, ref) {
    const { id } = useParams();
    const [list, setList] = useState([]);
    const [invoice, setInvoice] = useState(null);

    useEffect(() => {
        GetAllTotals(id).then(resp => {
            if (Array.isArray(resp.data))
                setList(resp.data);
        })
    }, [id])


    return <Paper elevation={2}>
        {invoice && (
            <React.Fragment>
                <div>
                    <Button color='secondary' variant='contained' className='float-right' startIcon={<ArrowBack />} onClick={() => setInvoice(null)}>Back</Button>
                    <div className='clearfix' />
                </div>
                <ReceiptView entityId={invoice} editable={false} display={true} module={3} />
            </React.Fragment>
        )}
        {invoice === null && (
            <React.Fragment>
                <h4>Total Receipts - {list.length}</h4>
                {list.map((e, i) => {
                    return <div className='col-12 p-2 border' key={String(i)}>
                        <h6><small className='text-muted'>Payment Date: {simpleDate(e.paymentDate)}</small></h6>
                        <h6><small className='text-muted'>Confirmation Num:{e.confirmationNum}</small></h6>
                        <h6><small className='text-muted'>Amount Paid: ${e.total}</small></h6>
                        <h6><small className='text-muted'>Invoice Number: {e.invoiceNumber}</small></h6>
                        <Button variant='outlined' className='float-right' onClick={() => setInvoice(e.invoiceId)}>View Detail</Button>
                        <div className='clearfix' />
                    </div>
                })}
            </React.Fragment>
        )}
    </Paper>
}

export default React.forwardRef(ReceiptsView);