import React, { useImperativeHandle, forwardRef } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import CRUDView from '../../components/crud';
import ArrowIcon from '@material-ui/icons/ArrowRightOutlined';

function Statements({ editable = false, display = true }, ref) {

    const { id } = useParams();

    useImperativeHandle(ref, () => ({
        validate: () => {
            return Promise.resolve()
        },
        submit: () => {
            return Promise.resolve()
        }
    }))

    const history = useHistory();

    const columns = [
        { field: 'invoiceNumber', title: 'Invoice Number', isGrid: true },
        { field: 'name', title: 'Invoice Cycle', isGrid: true },
        { field: 'companyName', title: 'Company Name', isGrid: true },
        { field: 'dotNumber', title: 'DOT Number', isGrid: true },
        { field: 'fiscalYear', title: 'Fiscal Year', isGrid: true },
        { field: 'status', title: 'Status', isGrid: true },
        { field: 'invoiceDate', title: 'Invoice Date', type: "datetime", isGrid: true },
        { field: 'dueDate', title: 'Due Date', type: "datetime", isGrid: true },
        { field: 'paidDate', title: 'Payment Date', type: "datetime", isGrid: true },
        { field: 'finalizeDue', title: 'Finalize Due', type: "datetime", isGrid: true },
        { field: "createdOn", title: "CreatedOn", type: "datetime", isGrid: true },
        { field: "createdBy", title: "CreatedBy", isGrid: true },
        { field: 'amountDue', title: 'Amount Due', type: 'numeric', isGrid: true },
        { field: 'amountPaid', title: 'Amount Paid', type: 'numeric', isGrid: true },
        { field: 'total', title: 'Total Amount', type: 'numeric', isGrid: true }
    ]


    const actions = [
        { icon: ArrowIcon, tooltip: 'View Invoice', onClick: (event, rowData) => history.push('/invoices/' + rowData.id) }
    ]

    return <React.Fragment>
        <CRUDView actions={actions}
            url={process.env.REACT_APP_API_URL + '/Statement/Company/' + id} id='id' columns={columns} title={'Invoices'} allowAdd={editable} allowDelete={editable} allowEdit={editable} pageCount={5} />
    </React.Fragment>
}

export default forwardRef(Statements);

