import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import CRUDView from '../../../components/crud';
import BackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useParams, useHistory } from 'react-router-dom';
import { GetCycle, AddCycle, UpdateCycle } from '../../../REST/administration';
import { FormView, Field } from '../../../components/form/Index';

const months = [
    { label: '1-January', value: 1 },
    { label: '2-February', value: 2 },
    { label: '3-March', value: 3 },
    { label: '4-April', value: 4 },
    { label: '5-May', value: 5 },
    { label: '6-June', value: 6 },
    { label: '7-July', value: 7 },
    { label: '8-August', value: 8 },
    { label: '9-September', value: 9 },
    { label: '10-October', value: 10 },
    { label: '11-November', value: 11 },
    { label: '12-December', value: 12 },
]

const days = [
    { label: '1st of Month', value: 1 },
    { label: '5th of Month', value: 5 },
    { label: '10th of Month', value: 10 },
    { label: '15th of Month', value: 15 },
    { label: '20th 0f Month', value: 20 },
    { label: '24th 0f Month', value: 24 },
    { label: '25th 0f Month', value: 25 },
    { label: '26th 0f Month', value: 26 },
    { label: '27th 0f Month', value: 27 },
    { label: 'Last 0f Month', value: -1 }
]

const backdays = [
    { label: 'Last 1 Month', value: 1 },
    { label: 'Last 3 Months', value: 2 },
    { label: 'Last 1 Year', value: 3 },
    { label: 'Other', value: 4 }
]

const types = [
    { label: 'Month', value: 1 },
    { label: 'Year', value: 2 },
]


export default function NoticesView() {
    const { id } = useParams();
    const history = useHistory();

    const config = [
        { field: 'name', title: 'Invoice Cycle Name', type: 'text', isGrid: true },
        { field: 'description', title: 'Description', type: 'textarea', isGrid: true },
    ]

    const actions = [
        { icon: AddIcon, tooltip: 'New Record', isFreeAction: true, onClick: () => history.push('/admin/cycles/0') },
        { icon: EditIcon, tooltip: 'Edit Record', onClick: (evt, rowData) => history.push('/admin/cycles/' + rowData.id) }
    ]

    if (id)
        return <DetailsView />

    return <CRUDView link='#admin' url={`${process.env.REACT_APP_API_URL}/Cycle`} columns={config} title='Invoice Cycles' actions={actions}
        allowEdit={false}
        allowAdd={false}
        allowDelete={false}
        id='id' />
}

function DetailsView({ options = [] }) {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const history = useHistory();
    const formView = useRef(null);

    useEffect(() => {
        if (id !== 0 && id && id !== '0')
            GetCycle(id).then(response => setData(response.data))
    }, [id])

    const handleSave = () => {
        let result = formView.current.getResult();
        if (result) {
            if (id === 0 || id === '0')
                handleAdd(result)
            else
                handleUpdate(result)
        }
    }

    const handleAdd = (result) => {
        AddCycle(result).then(response => {
            history.push('/admin/cycles/' + response.data.id)
        })
    }

    const handleUpdate = (result) => {
        UpdateCycle({ ...data, ...result })
    }

    const handleChange = () => {
        let result = formView.current.getCurrentResult();
        setData({ ...data, ...result });
    }

    const calcnoofbackdays  = () => {
        let result = formView.current.getCurrentResult();
        let days =0;
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        if(result.noOfBackDaysType==1){
            days = new Date(year, month, 0).getDate();
        } else if(result.noOfBackDaysType==3){
            days = ((year % 4 === 0 && year % 100 > 0) || year %400 == 0) ? 366 : 365;
        }else if(result.noOfBackDaysType==4){
            setData({ ...data, ...result });
        }
        setData({ ...data, ...result });
    }
  
    return <div className='row'><div className='col-6'>
        <FormView ref={formView} data={data}>
            <div className='row'><div className='col-12'>
                <Button className='float-right' variant="contained" color="secondary" startIcon={<BackIcon />} href='#admin/cycles'>Back</Button>
                <div className='clearfix' />
            </div></div>
            <Field.TextBox label='Name' required field='name' />
            <Field.TextArea label='Description' required field='description' />
            <Field.TextBox label='Generate Invoice Template Every' required field='frequency' />
            <div className='row'>
                <Field.DropDown className='col' changeEvt={handleChange} label='Time Frame' required field='frequencyType' options={types} />
                <Field.DropDown className='col' label='Date' required field='frequencyDate' unordered options={days} />
                {data && (data.frequencyType === 2 || data.frequencyType === '2') && <Field.DropDown unordered className='col' label='Month' required field='frequencyMonth' options={months} />}
            </div>
            <Field.TextBox label='Template Finalization Due' field='templateDue' required />
            <div className='row'>            
                 <Field.DropDown className='col' changeEvt={calcnoofbackdays} label='No Of Back Days Type' required field='noOfBackDaysType' options={backdays} />           
                 {data && (data.noOfBackDaysType === 4 || data.noOfBackDaysType === '4') && <Field.TextBox label='Event Pick up range'  field='noOfBackDays' /> }
                 <Field.CheckBox className='col' label='Allow Events' field='allowEvents' />
            </div>
            <Field.TextBox label='Payment Due' field='paymentDue' required />
            <div className='row'>
                <div className='col'>
                    <Button fullWidth variant='outlined' color='secondary' onClick={handleSave}>Save</Button>
                </div>
                <div className='col'>
                    <Button fullWidth variant='outlined' color='secondary' href='#admin/cycles'>Back</Button>
                </div>
            </div>
        </FormView >
    </div>
    </div>
}