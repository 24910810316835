import React from 'react';
import CRUDView from '../../../components/crud';
import Details from '@material-ui/icons/Ballot';
import { useHistory } from 'react-router-dom';

export default function CameraPricing() {
    const history = useHistory();
    const config = [
        { field: 'name', title: 'Camera Name', type: 'text', isGrid: true },
        { field: 'description', title: 'Description', type: 'textarea', isGrid: true },
    ]

    const actions = [
        { icon: Details, tooltip: 'View Pricing Details', onClick: (evt, rowData) => history.push('/admin/pricingdetails/' + rowData.id) }
    ]

    return <CRUDView actions={actions} link='#admin' url={`${process.env.REACT_APP_API_URL}/camerapricing`} columns={config} title='Camera Pricing' allowDelete={false} id='id' />
}
