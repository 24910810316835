import React, { useEffect, useState } from 'react';
//import CRUDView from '../../components/crud';
import KendoView from '../../components/crud/kendo';
import { useParams, useHistory } from 'react-router-dom';
import { GetOptions, GetEvent, UpdateStatus } from '../../REST/cameraEvents';
import { GetPermissions } from '../../REST/security';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DetailView from './details';
import { authenticationService } from '../../components/authorization';
import ModuleTemplate from '../../components/templates/module';
import StatusTemplate from '../../components/templates/status';
import SpeedTemplate from '../../components/templates/speeddial';
import Invoices from './invoice';
// Icons
import CommentIcon from '@material-ui/icons/Comment';
import NotesIcon from '@material-ui/icons/NoteAdd';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DescriptionIcon from '@material-ui/icons/Description';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PayIcon from '@material-ui/icons/Payment';
// Other Modules
import NotesView from '../Widgets/Notes';
import NoticeView from '../Widgets/Notices';
import AttachmentView from '../Widgets/Files';
import ReceiptView from '../Widgets/Receipt';

export default function CameraEvents() {
    const [refresh, setRefresh] = useState(false);
    const [options, setOptions] = useState({ companies: [], skus: [], charges: [], cameras: [] })
    const { id } = useParams();
    const history = useHistory();

    const config = [
                 { field: 'id', title: 'Event Id', isGrid: true },                
                 { field: 'cameraName', title: 'Camera Name', isGrid: true },
                 { field: 'vehicleType', title: 'Carrier Type', isGrid: true },
                 { field: 'activity', title: 'Activity Type', isGrid: true },
                 { field: 'plate_Number', title: 'License Plate / Tail Number', isGrid: true },
                 { field: 'plate_Region', title: 'Region', isGrid: true },
                 { field: 'dotNumber', title: 'DOT', isGrid: true },
                 { field: 'name', title: 'Company Name', isGrid: true },
                 { field: 'activity_Time', title: 'Activity Date', type: 'datetime', isGrid: true },
                 { field: 'statusName', title: 'Status', isGrid: true },
                 { field: 'charge', title: 'Charge', type: 'amount', isGrid: false },
                 { field: 'address1', title: 'Address ', isGrid: true },
                 { field: 'address2', title: 'Address (Contd.)', isGrid: true },
                 { field: 'city', title: 'City', isGrid: true },
                 { field: 'state', title: 'State', isGrid: true },
                 { field: 'zip', title: 'Zip', isGrid: true },
                 { field: 'notes', title: 'Notes', isGrid: true }
        
      
    ]

    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`

    const url = process.env.REACT_APP_KENDO_API + '/api/Reports/Events' + tokenParam

    useEffect(() => {
        GetOptions().then(response => {
            if (Array.isArray(response.data.companies) && Array.isArray(response.data.skus) && Array.isArray(response.data.charges))
                setOptions(response.data);
        })
    }, [refresh])

    if (id !== undefined && id !== null)
        return <EventsView options={options} refresh={refresh} onRefresh={() => setRefresh(!refresh)} />

    return <KendoView grid='CAMERA_GRID' onClick={(id) => history.push('/events/' + id)} back='#' columns={config} url={url} title='Camera Events' param='id' component={<IconButton href='#events/0'><AddIcon /></IconButton>} />
}


function EventsView({ options, refresh, onRefresh }) {
    const [access, setAccess] = useState([]);
    const [details, setDetails] = useState(null);
    const { id } = useParams();
    const role = authenticationService.getRole();

    useEffect(() => {
        if (id !== 0 && id !== '0')
            GetEvent(id).then(response => {
                if (response.data != null)
                    GetPermissions(5, response.data.status, role).then(resp => {
                        if (Array.isArray(resp.data)) {
                            setDetails(response.data)
                            setAccess(resp.data);
                        }
                    })
            })
    }, [id, role, refresh])

    const handleStatus = (status) => {
        UpdateStatus(id, status).then(onRefresh)
    }

    if (id === 0 || id === '0')
        return <DetailView options={options} refresh={refresh} onRefresh={onRefresh} />

    const actions = [
        { name: 'Details', icon: <DescriptionIcon />, module: 1, component: <DetailView options={options} refresh={refresh} onRefresh={onRefresh} /> },
        { name: 'Notes', icon: <CommentIcon />, module: 2, component: <NotesView module={5} entityId={id} /> },
        { name: 'Notices', icon: <NotesIcon />, module: 3, component: <NoticeView module={5} entityId={id} /> },
        { name: 'Attachments', icon: <AttachmentIcon />, module: 4, component: <AttachmentView module={5} entityId={id} /> },
        { name: 'Receipt', icon: <ReceiptIcon />, module: 5, component: <ReceiptView module={4} entityId={id} /> },
        { name: 'Invoices', icon: <PayIcon />, module: 6, component: <Invoices entityId={id} /> },
    ]
    /*Need to Change templates below. Make it One*/

    return <React.Fragment>
        {id !== 0 && id !== '0' && <h6 className='text-center'><small className='text-muted'>Event Id: {id}</small></h6>}
        <SpeedTemplate entityId={id} link='#events' entity={details} access={access} actions={actions} />
        <StatusTemplate settings={access} handleClick={handleStatus}>
            <ModuleTemplate entity={details} settings={access} module={1} component={<DetailView options={options} refresh={refresh} onRefresh={onRefresh} />} />
            <ModuleTemplate entity={details} settings={access} module={2} component={<NotesView module={5} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={3} component={<NoticeView module={5} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={4} component={<AttachmentView module={5} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={5} component={<ReceiptView module={4} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={6} component={<Invoices entityId={id} />} />
        </StatusTemplate>

    </React.Fragment>
}

