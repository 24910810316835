import React, { useEffect } from 'react';
import './App.css';
import Login from './Modules/Authentication/login';
import Loader from './components/loading';
import Header from './components/header';
import Alert from './components/alert';
import PrivateRoute from './Modules/Authentication/private';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import DefaultSettings from './components/settings';
//Routes Start Here 
import Dashboard from './Modules/Dashboard';
import Application from './Modules/Applications';
import Permit from './Modules/Permits';
import Statement from './Modules/Statements';
import Company from './Modules/Company';
import CameraEvent from './Modules/Camera';
import HelpView from './Modules/HelpContext';
import { PaymentView } from './Modules/Statements/payment';
import ExternalPayment from './Modules/Statements/external';
import Search from './Modules/Camera/search';
import SignUp from './Modules/Company/create'
import Administration from './Modules/Administration';
import Security from './Modules/Security/home';
import Reset from './Modules/Authentication/reset';
import ResetLogin from './Modules/Authentication/loginreset';
import MyAccount from './Modules/Authentication/myaccount';
import Reports from './Modules/Reports/index';
import Images from './Modules/Camera/images';
import CameraEventImages from './Modules/CameraImages';
import { PaymentReceipt } from './Modules/Statements/receipt';

import './EventsTemplate.css';



let theme = createMuiTheme({
  typography: {
    "fontFamily": "\"Nanum Gothic\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  palette: {
    secondary: {
      main: '#000000'
    }
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        fontSize: '1em'
      }
    },
    MuiSpeedDialAction: {
      staticTooltipLabel: {
        minWidth: 'max-content'
      }
    }
  }
});
//.MuiSpeedDialAction-tooltipPlacementLeft .MuiSpeedDialAction-staticTooltipLabel
theme = responsiveFontSizes(theme);

function App() {
  const headerView = React.useRef(null);
  const loaderView = React.useRef(null);
  const alertView = React.useRef(null);
  useEffect(() => {
    if (loaderView && loaderView.current)
      DefaultSettings.setLoader(loaderView.current);
    if (alertView && alertView.current)
      DefaultSettings.setAlert(alertView.current);
  }, [loaderView, alertView])


  return (

    <ThemeProvider theme={theme}>
      <Router>
        <Header ref={headerView} />
        <Loader ref={loaderView} />
        <Alert ref={alertView} />
        <Switch>
          <Route exact path='/payment/:id' render={props => <PaymentView {...props} header={headerView} />} />
          <Route exact path='/' component={Dashboard} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/search' component={Search} />
          <Route exact path='/myaccount' component={MyAccount} />
          <Route exact path='/loginreset/:type' component={ResetLogin} />
          <Route exact path='/statement/:id' component={ExternalPayment} />
          <Route exact path='/reset/:id' component={Reset} />
          <Route exact path='/help/:topic?/:article?/:story?' component={HelpView} />
          <Route exact path='/images/:id' component={Images} />
          <PrivateRoute exact path='/applications/:id?' component={Application} />
          <PrivateRoute exact path='/permits/:id?' component={Permit} />
          <PrivateRoute exact path='/company/:id?' component={Company} />
          <PrivateRoute exact path='/events/:id?' component={CameraEvent} />
          <PrivateRoute exact path='/eventsimages/:id?' component={CameraEventImages} />
          
          <PrivateRoute exact path='/invoices/:id?' component={Statement} />
          <PrivateRoute exact path='/reports' component={Reports} />
          <PrivateRoute path='/admin' component={Administration} />
          <PrivateRoute path='/security' component={Security} />
          <PrivateRoute path='/connectreceipt' component={PaymentReceipt} />
          <Route path='/' render={() => <p>Not Found</p>} />
        </Switch>
      </Router>
    </ThemeProvider>

  );
}

export default App;


// Check Admin Routes in Server Side - Verify Token if Is Internal



// REACT_APP_API_URL=https://devgcnconnect.azdot.gov/API
// REACT_APP_BASE_URL=https://devgcnconnect.azdot.gov
// REACT_APP_PROD_URL=https://devgcnconnect.azdot.gov
// REACT_APP_GRID_URL=https://devgcnconnect.azdot.gov/KendoUI
// REACT_APP_PAYMENT_URL=https://devgcnconnect.azdot.gov/TokenApp/api/Token
// REACT_APP_DEV_PAYMENT=https://sbcs.billerpayments.com/ssoauth/saml/cust/soadotgcnpa
// REACT_APP_PROD_PAYMENT=https://www.billerpayments.com/ssoauth/saml/cust/soadotgcnpa
// REACT_APP_TEMPLATE_UI=https://devgcnconnect.azdot.gov/TemplateUI
// REACT_APP_TEMPLATE_API=https://devgcnconnect.azdot.gov/TemplateAPI
// REACT_APP_PROD_PARTNERKEY=7793404448
// REACT_APP_DEV_PARTNERKEY=3676989464
// REACT_APP_ADMIN_ROLES=Administrator,Super Administrator,Ops Front Desk,Airport Operations Mgmt,Ops ground – Events only
// REACT_APP_KENDO_API=https://devgcnconnect.azdot.gov/KendoAPI
// REACT_APP_HIDE_ROLE=e37a906e-126a-479d-88aa-4f3c861ae27e
// REACT_APP_INVOICE_URL=https://devgcnconnect.azdot.gov