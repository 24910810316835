import React from 'react';
import KendoView from '../../components/crud/kendo';
import { authenticationService } from '../../components/authorization';

const config = [
    { title: 'Permit Id', field: 'id', isGrid: true, type: 'text' },
    { title: 'Application Type', field: 'name', isGrid: true, type: 'text' },
    { title: 'Plate Number', field: 'plateNumber', isGrid: true, type: 'text' },
    { title: 'Plate Region', field: 'plateRegion', isGrid: true, type: 'text' },
    { title: 'Status', field: 'status', isGrid: true, type: 'text' },
    { title: 'Expiry Date', field: 'expiryDate', isGrid: true, type: 'date' },
    { title: 'Fiscal Year', field: 'fiscal', isGrid: true, type: 'text' },
    { title: 'Company Name', field: 'company', isGrid: true, type: 'text' },
    { title: 'DOT Number', field: 'dotnumber', isGrid: true, type: 'text' },
    { title: 'Phone', field: 'phone', isGrid: true, type: 'text' },
    { title: 'Email', field: 'email', isGrid: true, type: 'text' },
    { title: 'Approved Date', field: 'approvedDate', isGrid: true, type: 'date' },
    { title: 'Paid Date', field: 'paidDate', isGrid: true, type: 'date' },
    { title: 'Sent to Risk', field: 'riskSent', isGrid: true, type: 'text' },
    { title: 'Received from Risk', field: 'riskReceived', isGrid: true, type: 'date' },
    { title: 'Created By', field: 'createdBy', isGrid: true, type: 'text' },
    { title: 'Updated By', field: 'updatedBy', isGrid: true, type: 'text' },
]



export default function Permit() {
    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`
    return <KendoView grid='PERMIT_REPORT' param='id' link='#' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Permit' + tokenParam} columns={config} title='Permits' />
}