import React, { useEffect, useState } from 'react';
import { GetEvents } from '../../REST/cameraEvents';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';

export default function ImageGrid() {
    const { id } = useParams();
    const [events, setEvents] = useState([]);
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        GetEvents(id - 1).then(result => {
            setEvents(result.data);
        })
    }, [id])

    const convertUTCDateToLocalDate = (date) => {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset - 3);

        return newDate;
    }
    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };
      const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
      };

    return <div className='row'>
        {events.map(e => {
            return <div key={e.id} className='col-3'>
              
              <a target="_blank"  rel="noopener noreferrer"  href={'#/events/' + e.id} >
                <img width='100%' height='auto' src={`https://gcn-camera.s3.amazonaws.com/${e.imageUid}_context.jpg`} alt={e.cameraId} /></a> 
                <h6><small><div>Event Id: {e.id}   <a target="_blank"  rel="noopener noreferrer"  href={`https://gcn-camera.s3.amazonaws.com/${e.imageUid}_plate.jpg`}>Plate Image</a></div></small></h6>
                <h6><small><div>Camera Id: {e.cameraId}</div></small></h6>
                <h6><small><div>Date/Time: {new Date(Date.parse(e.activityTime)).toLocaleDateString()} {convertUTCDateToLocalDate(new Date(Date.parse(e.activityTime))).toLocaleTimeString()}</div></small></h6>
                <h6><small><div>Plate And Region: {`${e.plateNumber} , ${e.plateRegion}`}</div></small></h6>
                
            </div>
        })}
        <div className='col-12'>
            {id > 1 && <Button variant='contained' href={`#images/${parseInt(id) - 1}`} onClick={scrollToTop}>Prev</Button>}           
            {id && <Button variant='contained' href={`#images/${parseInt(id) + 1}`} onClick={scrollToTop} >Next</Button>}
           
        </div>
    </div >
}