import React, { useEffect, useState, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
//import { tableIcons } from '../../components/crud/icons';
//import ArrowIcon from '@material-ui/icons/ArrowRightOutlined';
import KendoView from '../../components/crud/kendo';
//import MaterialTable from 'material-table';
import { GetApplication, GetOptions, AddRecord, UpdateStatus } from '../../REST/application';
import { GetPermissions } from '../../REST/security';
import { useParams, useHistory } from 'react-router-dom';
import { FormView, Field } from '../../components/form/Index';
import Button from '@material-ui/core/Button';
import DetailsView from './details';
import TermsView from './terms';
import Vehicles from './vehicles';
import Attachments from './attachments';
import Payment from './payment';
import Company from './company';
import Insurance from './insurance';
import Permits from './permits';
import { authenticationService } from '../../components/authorization';
import ModuleTemplate from '../../components/templates/module';
import StatusTemplate from '../../components/templates/status';
import SpeedTemplate from '../../components/templates/speeddial';
import InvoiceView from './invoice';
// Icons
import CommentIcon from '@material-ui/icons/Comment';
import NotesIcon from '@material-ui/icons/NoteAdd';
import CommuteIcon from '@material-ui/icons/Commute';
import AttachmentIcon from '@material-ui/icons/Attachment';
import BusinessIcon from '@material-ui/icons/Business';
import InfoIcon from '@material-ui/icons/Info';
import PayIcon from '@material-ui/icons/AttachMoney';
import DescriptionIcon from '@material-ui/icons/Description';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BusIcon from '@material-ui/icons/Help';
// Other Modules
import NotesView from '../Widgets/Notes';
import NoticeView from '../Widgets/Notices';
import ReceiptView from '../Widgets/Receipt';
import ConfirmView from '../../components/confirm';

export default function Application() {
    const [create, setCreate] = useState(false);
    const history = useHistory();
    const { id } = useParams();

    const columns = [
        { field: 'id', title: 'Application Number' },
        { field: 'name', title: 'Type' },
        { field: 'fiscal', title: 'Fiscal Year' },
        { field: 'company', title: 'Company Name' },
        { field: 'phone', title: 'Phone' },
        { field: 'email', title: 'Email' },
        { field: 'status', title: 'Status' },
        { field: 'submitDate', title: 'Submitted Date', type: "date" },
        { field: 'riskSent', title: 'Sent to Risk On', type: "date" },
        { field: 'riskReceived', title: 'Received from Risk On', type: "date" },
        { field: 'alExpiration', title: 'AL Expiration', type: "date" },
        { field: 'glExpiration', title: 'GL Expiration', type: "date" },
        { field: 'wcExpiration', title: 'WC Expiration', type: "date" },
        { field: 'isSatisfied', title: 'Satisfied' },
        { field: 'approvedDate', title: 'Approved Date', type: "date" },
        { field: 'paidDate', title: 'Paid Date', type: "date" },
        { field: 'amountPaid', title: 'Amount Paid', type: 'amount' },
        { field: 'permitsIssued', title: 'Permits Issued', type: "numeric" },
        { field: 'createdOn', title: 'Created On', type: 'date' },
        { field: 'createdBy', title: 'Created By' },
        { field: 'updatedOn', title: 'Updated On', type: 'date' },
        { field: 'updatedBy', title: 'Updated By' }]

    // const actions = [
    //     { icon: AddIcon, isFreeAction: true, onClick: () => setCreate(true) },
    //     { icon: ArrowIcon, tooltip: 'View Application', onClick: (event, rowData) => history.push('applications/' + rowData.id) }
    // ]

    if (create)
        return <ApplicationCreate onSubmit={() => setCreate(false)} />

    if (id)
        return <ApplicationView />

    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`

    return <KendoView component={<IconButton color='secondary' onClick={() => setCreate(true)}><AddIcon /></IconButton>}
        param='id' grid='APPLICATION_GRID' back='#' sort='createdOn' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Application' + tokenParam} onClick={(id) => history.push('/applications/' + id)} columns={columns} title="Applications" />
}

function ApplicationView() {
    const [details, setDetails] = useState(null);
    const [access, setAccess] = useState([]);
    const { id } = useParams();
    const role = authenticationService.getRole();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        GetApplication(id).then(response => {
            if (response.data != null)
                GetPermissions(1, response.data.status, role).then(resp => {
                    if (Array.isArray(resp.data)) {
                        setDetails(response.data);
                        setAccess(resp.data);
                    }
                })
        })
    }, [id, role, refresh])

    const handleStatus = (status) => {
        UpdateStatus(id, status).then(() => setRefresh(!refresh))
    }

    const actions = [
        { name: 'Vehicles', icon: <CommuteIcon />, module: 1, component: <Vehicles /> },
        { name: 'Attachments', icon: <AttachmentIcon />, module: 2, component: <Attachments /> },
        { name: 'Company', icon: <BusinessIcon />, module: 8, component: <Company /> },
        { name: 'Insurance', icon: <InfoIcon />, module: 3, component: <Insurance /> },
        { name: 'Payment', icon: <PayIcon />, module: 6, component: <Payment /> },
        { name: 'Details', icon: <DescriptionIcon />, module: 7, component: <DetailsView /> },
        { name: 'Notes', icon: <CommentIcon />, module: 5, component: <NotesView module={1} entityId={id} /> },
        { name: 'Notices', icon: <NotesIcon />, module: 4, component: <NoticeView module={1} entityId={id} /> },
        { name: 'Receipt', icon: <ReceiptIcon />, module: 9, component: <ReceiptView module={1} entityId={id} /> },
        { name: 'Permits', icon: <BusIcon />, module: 10, component: <Permits entityId={id} /> },
        { name: 'Invoices', icon: <PayIcon />, module: 11, component: <InvoiceView entityId={id} /> }
    ]
    /*Need to Change templates below. Make it One*/
    return <React.Fragment>
        <SpeedTemplate entityId={id} link='#applications' access={access} actions={actions} />
        <StatusTemplate settings={access} handleClick={handleStatus}>
            <ModuleTemplate entity={details} settings={access} module={7} component={<DetailsView />} />
            <ModuleTemplate entity={details} settings={access} module={8} component={<Company />} />
            <ModuleTemplate entity={details} settings={access} module={1} component={<Vehicles />} />
            <ModuleTemplate entity={details} settings={access} module={2} component={<Attachments />} />
            <ModuleTemplate entity={details} settings={access} module={6} component={<Payment />} />
            <ModuleTemplate entity={details} settings={access} module={3} component={<Insurance />} />
            <ModuleTemplate entity={details} settings={access} module={5} component={<NotesView module={1} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={4} component={<NoticeView module={1} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={9} component={<ReceiptView module={1} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={10} component={<Permits />} />
            <ModuleTemplate entity={details} settings={access} module={11} component={<InvoiceView />} />
        </StatusTemplate>
    </React.Fragment>
}

function ApplicationCreate({ onSubmit }) {
    const confView = useRef(null);
    const formView = useRef(null);
    const [details, setDetails] = useState({ companies: [], permits: [] })
    const [terms, setTerms] = useState({ open: false, result: null })
    const history = useHistory();

    const addRecord = (result) => {
        if (result)
            AddRecord(result).then(response => {
                const { id } = response.data;
                if (id)
                    history.push('applications/' + id); onSubmit();
            })
    }

    const handleAdd = () => {
        let result = formView.current.getResult();
        if (result) {
            setTerms({ open: true, result })
        }
    }

    useEffect(() => {
        GetOptions().then(response => {
            setDetails(response.data)
        })
    }, [])

    const handleClose = () => confView.current.onClose();

    const handleAgree = () => {
        addRecord(terms.result)
        confView.current.onClose();
    }

    const handleOpen = () => confView.current.onOpen()

    const init = {}
    if (details.companies.length === 1)
        init.companyId = details.companies[0].value

    if (details.permits.length === 1)
        init.permitId = details.permits[0].value

    const years = [{ label: new Date().getFullYear(), value: new Date().getFullYear() }, { label: new Date().getFullYear() + 1, value: new Date().getFullYear() + 1 }]

    console.log(init)

    return <React.Fragment>
        <ConfirmView ref={confView} onClose={handleClose} onSubmit={handleAgree} setting='APPLICATION_AGREE_MESSAGE' title={'Confirmation'} />
        <TermsView open={terms.open} permitId={terms.result ? terms.result.permitId : null} onSubmit={handleOpen} onCancel={() => setTerms({ open: false, result: null })} />
        <FormView ref={formView} data={{ ...init, ...terms.result }}>
            <Field.DropDown field='companyId' label='Company' options={details.companies} required disabled={details.companies.length === 1} />
            <Field.DropDown field='permitId' label='Permit Type' options={details.permits} required disabled={details.permits.length === 1} />
            <Field.DropDown field='fiscal' label='Applying For Year' options={years} required />
            <Button onClick={handleAdd} variant='outlined'>Create Application</Button>
        </FormView>
    </React.Fragment>
}

