import React from 'react';
import CRUDView from '../../components/crud';
import LockIcon from '@material-ui/icons/LockOpen';
import { useHistory } from 'react-router-dom';

const config = [

    { field: 'roleName', title: 'Role Name', type: 'text', required: true, isGrid: true },
    { field: 'roleId', title: 'Role Id', type: 'numeric', required: true, isGrid: false },
    { field: 'isInternal', title: 'Internal', type: 'checkbox', isGrid: true },
    { field: 'isSystem', title: 'System', type: 'checkbox', isGrid: true },
]



export default function ModuleGroups() {
    const history = useHistory()

    const actions = [
        { icon: LockIcon, tooltip: 'Module Permissions', onClick: (evt, rowData) => history.push('/security/roles/' + rowData.roleId) }
    ]

    return <CRUDView link='#/security' actions={actions} url={process.env.REACT_APP_API_URL + '/RoleType'} columns={config} title='Role Types' id='id' />
}