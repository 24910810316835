import React from 'react';
import KendoView from '../../components/crud/kendo';
import { authenticationService } from '../../components/authorization';

const config = [
    { field: 'id', title: 'Application Number' },
    { field: 'name', title: 'Type' },
    { field: 'fiscal', title: 'Fiscal Year' },
    { field: 'company', title: 'Company Name' },
    { field: 'phone', title: 'Phone' },
    { field: 'email', title: 'Email' },
    { field: 'status', title: 'Status' },
    { field: 'submitDate', title: 'Submitted Date', type: "date" },
    { field: 'riskSent', title: 'Sent to Risk On', type: "date" },
    { field: 'riskReceived', title: 'Received from Risk On', type: "date" },
    { field: 'alExpiration', title: 'AL Expiration', type: "date" },
    { field: 'glExpiration', title: 'GL Expiration', type: "date" },
    { field: 'wcExpiration', title: 'WC Expiration', type: "date" },
    { field: 'isSatisfied', title: 'Satisfied' },
    { field: 'createdOn', title: 'Created On', type: "date" },
    { field: 'approvedDate', title: 'Approved Date', type: "date" },
    { field: 'paidDate', title: 'Paid Date', type: "date" },
    { field: 'amountPaid', title: 'Amount Paid', type: 'amount' },
    { field: 'permitsIssued', title: 'Permits Issued', type: "numeric" },
    { field: 'createdOn', title: 'Created On', type: 'date' },
    { field: 'createdBy', title: 'Created By' },
    { field: 'updatedOn', title: 'Updated On', type: 'date' },
    { field: 'updatedBy', title: 'Updated By' }
]



export default function Application() {
    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`
    return <KendoView grid='APPLICATIONS_REPORT' title='Applications' param='id' link='#' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Application' + tokenParam} columns={config} />
}