import React, { useImperativeHandle, forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import MessageView from '../components/message';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: '1400 !important'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide({ title = 'Not Provided', setting = 'NO_SETTING_KEY', onSubmit, onClose }, ref) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    useImperativeHandle(ref, () => ({
        onOpen: () => {
            setOpen(true)
        },
        onClose: () => {
            setOpen(false)
        }
    }))

    return <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        className={classes.root}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <MessageView fullScreen={false} setting={setting} title={title} />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Disagree
          </Button>
            <Button onClick={onSubmit} color="primary">
                Agree
          </Button>
        </DialogActions>
    </Dialog>
}

export default forwardRef(AlertDialogSlide);