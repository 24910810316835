import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { FormView, Field } from '../../components/form/Index';
import { GetComment, UpdateComment } from '../../REST/statement';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import ButtonGroup from '@material-ui/core/ButtonGroup';

function Comments({ editable = true, display = true, entityId }, ref) {
    const [remarks, setRemarks] = useState('');
    const formView = useRef(null);
    const [show, setShow] = useState(false);

    const onSave = () => {
        const result = formView.current.getResult();
        if (result)
            UpdateComment(entityId, { ...result, id: entityId }).then(response => { setRemarks(response.data); setShow(false) })
    }

    const handleShow = (val) => {
        if (!show) {
            setRemarks(val)
            setShow(true)
        }
    }

    const onCancel = () => {
        setShow(false)
    }

    useEffect(() => {
        if (show === false)
            GetComment(entityId).then(response => setRemarks(response.data))
    }, [entityId, display, show])

    return <div className='row my-2'>
        <div className='col-sm-6'>
            <FormView ref={formView} disabled={!editable} data={{ remarks }}>
                <div className='row'>
                    <div className='col-10'>
                        <Field.TextArea changeEvt={handleShow} field='remarks' label='Remarks' />
                    </div>
                    <div className='col-2 my-auto'>
                        {show && <ButtonGroup
                            orientation="vertical"
                            color="secondary">
                            <Button onClick={onSave} variant='outlined' startIcon={<SaveIcon />}>Save</Button>
                            <Button onClick={onCancel} variant='outlined' startIcon={<ClearIcon />}>Cancel</Button>
                        </ButtonGroup>}
                    </div>
                </div>
            </FormView >
        </div>
    </div>
}

export default forwardRef(Comments);