import React from 'react';
import CRUDView from '../../../components/crud';
import { GetCycles, RunInvoice } from '../../../REST/administration';
import { useState } from 'react';
import PlayIcon from '@material-ui/icons/PlayArrow';

export default function Transient() {
    const [options, setOptions] = useState([]);

    useState(() => {
        GetCycles().then(resp => {
            if (Array.isArray(resp.data))
                setOptions(resp.data.map(e => { return { label: e.name, value: e.id } }));
        })
    }, [])

    const config = [
        { field: 'invoiceCycle', title: 'Invoice Cycle', type: 'select', options: options, isGrid: true },
    ]

    const actions = [
        { icon: PlayIcon, tooltip: 'Run Invoice Cycle', onClick: (evt, rowData) => RunInvoice(rowData.id) }
    ]

    return <CRUDView actions={actions} maxItems={1} link='#admin' url={`${process.env.REACT_APP_API_URL}/Transient`} filtering={false} columns={config} title='Transient Invoice Cycle' allowDelete={false} id='id' />
}

