import React from 'react';
import KendoView from '../../components/crud/kendo';
import { authenticationService } from '../../components/authorization';

const config = [
    { field: "name", title: "Name", isGrid: true },
    { field: "dotnumber", title: "DOTNumber", isGrid: true },
    { field: "phone", title: "Phone", isGrid: true },
    { field: "email", title: "Email", isGrid: true },
    { field: "vendorCode", title: "Customer Code", isGrid: true },
    { field: "billingProfile", title: "Business Profile", isGrid: true },
    { field: "isTenant", title: "Lease Holder/Tenant", isGrid: true },
    { field: "statusName", title: "Status", isGrid: true },
    { field: "maxUsers", title: "Users", isGrid: true },
    { field: "createdOn", title: "CreatedOn", type: "datetime", isGrid: true },
    { field: "createdBy", title: "CreatedBy", isGrid: true },
    { field: "amountPaid", title: "Paid Amount", type: 'amount', isGrid: true },
    { field: "amountDue", title: "Due Amount", type: 'amount', isGrid: true },
    { field: "total", title: "Total Amount", type: 'amount', isGrid: true },
]



export default function Company() {
    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`
    return <KendoView grid='COMPANY_REPORT' link='#' url={process.env.REACT_APP_KENDO_API + '/api/Reports/Company' + tokenParam} columns={config} title='Company Details' param='id' />
}