import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus, faBuilding, faFileInvoiceDollar, faCalendarAlt, faInfo, faPhoneAlt, faChartBar, faUserLock, faSignInAlt, faUserAlt, faDollarSign, faFileWord, faCode, faList, faLockOpen, faUserCircle, faShieldAlt, faBusAlt, faCamera, faUsers, faCalendarCheck, faMoneyBill, faClock, faBell, faFile, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { faWpforms } from '@fortawesome/free-brands-svg-icons';
import { authenticationService } from '../../components/authorization';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
    parent: {
        cursor: 'pointer',
        transition: 'transform .2s',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
}));

export default function Dashboard() {

    if (authenticationService.user()) {
        return <Template list={home.map(e => {
            return { ...e, isSecure: !e.isSecure ? isSecure(e.module) : e.isSecure }
        })} title='Dashboard' />
    }
    return <div className='row bg-fill h-100 px-3' style={{ backgroundImage: 'url("https://www.nps.gov/common/uploads/banner_image/imr/homepage/99556161-1DD8-B71B-0B896E4D786C6B47.jpg")' }}>
        <div className='col-12 my-auto'>
            <Template list={init} />
        </div>
    </div>
}

export function AdminDashboard() {
    return <Template list={admin.map(e => {
        return { ...e, isSecure: isSecure(8) }
    })} title='Administration' />
}

export function SecurityDashboard() {
    return <Template list={security.map(e => {
        return { ...e, isSecure: isSecure(7) }
    })} title='Security' />

}

const isSecure = (module) => {
    return authenticationService.isAccess(module)
}

export function Template({ list = [], title }) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const handleLink =( link,title) => (evt) => {
        console.log(link)
        history.push({pathname:link,state:{title:title}})
    }

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    return <React.Fragment>
        {title && <h5 className='text-center p-3'>{title}</h5>}
        <div className='row px-3'>
            {list.filter(e => e.isSecure).map((e, i) => {
                return <div onClick={handleLink(e.href,e.title)} key={i} className={clsx(classes.parent, 'text-center', 'col-sm-6', 'col-md-3', 'col-xs-12', 'mt-3')}>
                    <div className='bg-white p-3 rounded'>
                        <Zoom in timeout={transitionDuration} unmountOnExit style={{
                            transitionDelay: `${transitionDuration.exit}ms`,
                        }}>
                            <FontAwesomeIcon icon={e.icon} size='4x' />
                        </Zoom>
                        <h5 className='p-3'>{e.title}</h5>
                    </div>
                </div>
            })}
        </div>
    </React.Fragment >
}

const home = [
    { icon: faWpforms, title: 'Applications', isSecure: isSecure(1), href: '/applications', module: 1 },
    { icon: faBus, title: 'Permits', isSecure: isSecure(2), href: '/permits', module: 2 },
    { icon: faBuilding, title: 'Company Details', isSecure: isSecure(3), href: '/company', module: 3 },
    { icon: faFileInvoiceDollar, title: 'Invoices', isSecure: isSecure(4), href: '/invoices', module: 4 },
    { icon: faCalendarAlt, title: 'Events', isSecure: isSecure(5), href: '/events', module: 5 },
    { icon: faInfo, title: 'Help', isSecure: true, href: '/help', module: 10 },
    { icon: faPhoneAlt, title: 'Contact Support', isSecure: true, href: 'https://apps.azdot.gov/contact_adot/', module: 9 },
    { icon: faChartBar, title: 'Reports', isSecure: isSecure(6), href: '/reports', module: 6 },
    { icon: faUserLock, title: 'Administration', isSecure: isSecure(8), href: '/admin', module: 8 },
    { icon: faShieldAlt, title: 'Security', isSecure: isSecure(7), href: '/security', module: 7 }
]

const admin = [
    { icon: faUsers, title: 'Users', isSecure: isSecure(8), href: '/admin/users' },
    { icon: faBusAlt, title: 'Permit Types', isSecure: isSecure(8), href: '/admin/permits' },
    { icon: faFile, title: 'Attachment Types', isSecure: isSecure(8), href: '/admin/attachments' },
    { icon: faCamera, title: 'Cameras', isSecure: isSecure(8), href: '/admin/cameras' },
    { icon: faMoneyBill, title: "SKU's", isSecure: isSecure(8), href: '/admin/skus' },
    { icon: faCalendarCheck, title: 'Invoice Cycles', isSecure: isSecure(8), href: '/admin/cycles' },
    { icon: faFileWord, title: 'Notices', isSecure: isSecure(8), href: '/admin/notices' },
    { icon: faCode, title: 'Content Messages', isSecure: isSecure(8), href: '/admin/content' },
    { icon: faClock, title: 'Status Schedules', isSecure: isSecure(8), href: '/admin/schedules' },
    { icon: faBell, title: 'Notification', isSecure: isSecure(8), href: '/admin/notifications' },
    { icon: faCalendarDay, title: 'Transient Invoice', isSecure: isSecure(8), href: '/admin/transient' },
    { icon: faDollarSign, title: 'Camera Pricing', isSecure: isSecure(8), href: '/admin/camerapricing' },
]

const security = [
    { icon: faLockOpen, title: 'Permissions', isSecure: isSecure(7), href: '/security/permissions' },
    { icon: faList, title: 'Modules', isSecure: isSecure(7), href: '/security/modules' },
    { icon: faUserCircle, title: 'Role Types', isSecure: isSecure(7), href: '/security/roles' },
]

const init = [
    { icon: faSignInAlt, title: 'Account Login', isSecure: true, href: '/login' },
    { icon: faWpforms, title: 'Apply For Permit', isSecure: true, href: '/applications' },
    { icon: faUserAlt, title: 'Sign Up For Account', isSecure: true, href: '/signup' },
    { icon: faDollarSign, title: 'Pay Airport Use Fee', isSecure: true, href: '/search' },
]