
import React, { useState, useRef } from 'react';
import { FormView, Field } from '../../components/form/Index';
import AgeingReport from './ageing';
import ApplicationsReport from './applications';
import DailyReport from './daily';
import SkuReport from './sku';
import SkuActivityReport from './skuactivity';
import InvoiceReport from './invoice';
import CompanyReport from './company';
import PermitsReport from './permit';
import YearlyReport from './yearly';
import TemporaryInvoices from './temporary';
import NotificationLog from './notificationlog';

const options = [
    { label: 'Aging Report', value: 1 },
    { label: 'Applications Report', value: 2 },
    { label: 'Daily Report', value: 3 },
    { label: 'Sku Report', value: 4 },
    { label: 'Sku Activity Report', value: 5 },
    { label: 'Invoice Report', value: 6 },
    { label: 'Company Report', value: 7 },
    { label: 'Permits Report', value: 8 },
    { label: 'Yearly Report', value: 9 },
    { label: 'Temporary Invoices', value: 10 },
    { label: 'Notification Log', value: 11 },
]

export default function Reports() {

    const [step, setStep] = useState(0);
    const [data, setData] = useState(null);
    const formView = useRef(null);
    const handleChange = (x) => {
        setStep(x);
        setData({ reports: x })
    }

    return (
        <div className="h-100 w-100">
            <FormView ref={formView} data={data}>
                <Field.DropDown className='col' label='Reports' changeEvt={handleChange} options={options} required field='reports' />
            </FormView>
            <div style={{ height: 'calc(100% - 100px)' }}>
                {(step === 1 || step === '1') && <AgeingReport />}
                {(step === 2 || step === '2') && <ApplicationsReport />}
                {(step === 3 || step === '3') && <DailyReport />}
                {(step === 4 || step === '4') && <SkuReport />}
                {(step === 5 || step === '5') && <SkuActivityReport />}
                {(step === 6 || step === '6') && <InvoiceReport />}
                {(step === 7 || step === '7') && <CompanyReport />}
                {(step === 8 || step === '8') && <PermitsReport />}
                {(step === 9 || step === '9') && <YearlyReport />}
                {(step === 10 || step === '10') && <TemporaryInvoices />}
                {(step === 11 || step === '11') && <NotificationLog />}
            </div>
        </ div >
    )

}
