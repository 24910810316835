import React from 'react';
import CRUDView from '../../components/crud';
import { useParams } from 'react-router-dom';

const config = [
    {
        field: 'groupId', title: 'Modules', type: 'select', options: [
            { label: 'Applications', value: 1 },
            { label: 'Permits', value: 2 },
            { label: 'Company', value: 3 },
            { label: 'Invoices', value: 4 },
            { label: 'Camera Events', value: 5 },
            { label: 'Reports', value: 6 },
            { label: 'Security', value: 7 },
            { label: 'Administration', value: 8 },
        ],
        required: true,
        isGrid: true
    },
    { field: 'allowEdit', title: 'Allow Edit for Permission Module Configuration with Edit Enabled', type: 'checkbox', isGrid: true },
    { field: 'allowDisplay', title: 'Display Permission Module Configuration with Display Enabled ', type: 'checkbox', isGrid: true }
]

export default function Access() {

    const { id } = useParams();

    return <CRUDView link='#security/roles' url={process.env.REACT_APP_API_URL + '/ModuleSecurity/' + id} columns={config} title='Module Permissions' id='id' />
}