import React, { useRef, useState, useEffect } from 'react';
import { FormView, Field } from '../../components/form/Index';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

// REST
import { UpdateMyAccount, GetUser } from '../../REST/authorize';

export default function LoginReset() {
    const formView = useRef(null);
    const [data, setData] = useState(null);

    let history = useHistory();


    let username = localStorage.getItem('user')

    useEffect(() => {
        GetUser(username).then(res => {
            setData(res.data);
        })
    }, [username])

    const submitProfile = () => {
        let result = formView.current.getResult();
        if (result) {
            let res = {};
            res['FirstName'] = result.firstName;
            res['LastName'] = result.lastName
            UpdateMyAccount(username, { ...res }).then(response => {
                setData(response.data)
            })
        }
    }

    return (
        <div className='row  h-100'>
            <div className="col-sm-12">
                <form onSubmit={(e) => { e.preventDefault(); submitProfile(); }}>
                    <FormView ref={formView} data={data}>
                        <div className="col-sm-6"><Button type='submit' className=' float-right mt-2' variant='outlined' color='secondary'>Save</Button>
                            <Button onClick={() => { history.goBack() }} color='secondary' variant='contained' startIcon={<ArrowBack />}>Back</Button>
                            <div className="clearfix" />
                        </div>
                        <Field.TextBox className="col-sm-6" field='firstName' label='First Name' />
                        <Field.TextBox className="col-sm-6" field='lastName' label='Last Name' />
                    </FormView>
                </form>
            </div>
        </div>)
}