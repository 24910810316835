import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GetCompany, UpdateStatus } from '../../REST/company';
import { GetPermissions } from '../../REST/security';
import KendoView from '../../components/crud/kendo';
import Details from './details';
import Profile from './profile';
import Invoices from './invoice';
import Address from './address';
import Users from './users';
import ModuleTemplate from '../../components/templates/module';
import StatusTemplate from '../../components/templates/status';
import SpeedTemplate from '../../components/templates/speeddial';
import { authenticationService } from '../../components/authorization';
import Permits from './permits';
import Statements from './statements';
import Button from '@material-ui/core/Button';
import CompanyAdd from '../Camera/company';
import { states } from '../Camera/details';
// Icons
import CommentIcon from '@material-ui/icons/Comment';
import NotesIcon from '@material-ui/icons/NoteAdd';
import PeopleIcon from '@material-ui/icons/People';
import AttachmentIcon from '@material-ui/icons/Attachment';
import EventIcon from '@material-ui/icons/CalendarViewDaySharp';
import InfoIcon from '@material-ui/icons/Info';
import PayIcon from '@material-ui/icons/AttachMoney';
import DescriptionIcon from '@material-ui/icons/Description';
import MailIcon from '@material-ui/icons/Mail';
import ListIcon from '@material-ui/icons/List';
import HelpIcon from '@material-ui/icons/InfoOutlined';
// Other Modules
import NotesView from '../Widgets/Notes';
import NoticeView from '../Widgets/Notices';
import AttachmentView from '../Widgets/Files';
import EventsView from './events';

export default function Company() {
    const { id } = useParams();
    const history = useHistory();
    const [add, setAdd] = useState(false)

    const config = [
        { field: "id", title: "Company Id", isGrid: true },
        { field: "name", title: "Name", isGrid: true },
        { field: "dotNumber", title: "DOTNumber", isGrid: true },
        { field: "phone", title: "Phone", isGrid: true },
        { field: "email", title: "Email", isGrid: true },
        { field: "vendorCode", title: "Customer Code", isGrid: true },
        { field: "billingProfile", title: "Business Profile", isGrid: true },
        { field: "isTenant", title: "Lease Holder/Tenant", isGrid: true },
        { field: "statusName", title: "Status", isGrid: true },
        { field: "startDate", title: "Begin Date", isGrid: true, type: 'date' },
        { field: "endDate", title: "End Date", isGrid: true, type: 'date' },
        { field: "createdOn", title: "CreatedOn", type: "datetime", isGrid: true },
        { field: "createdBy", title: "CreatedBy", isGrid: true }
    ]

    const tokenParam = `?token=${encodeURIComponent(authenticationService.getRefreshToken())}`

    const url = process.env.REACT_APP_KENDO_API + '/api/Reports/CompanyGrid' + tokenParam

    if (id)
        return <CompanyDetails />

    return <div className='row h-100'>
        <div className='col-12'>
            {authenticationService.isAdmin() && <Button onClick={() => setAdd(true)}>Add New Company</Button>}
            <CompanyAdd open={add} handleClose={() => setAdd(false)} states={states} />
        </div>
        <div className='col-12 h-100'>
            <KendoView grid='COMPANY_GRID' back='#' onClick={(id) => history.push('/company/' + id)} title='Company Information' url={url} param='id' columns={config} />
        </div>
    </div>
}

function CompanyDetails() {
    const [details, setDetails] = useState(null);
    const [access, setAccess] = useState([]);
    const { id } = useParams();
    const role = authenticationService.getRole();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        GetCompany(id).then(response => {
            if (response.data != null)
                GetPermissions(3, response.data.status, role).then(resp => {
                    if (Array.isArray(resp.data)) {
                        setDetails(response.data);
                        setAccess(resp.data);
                    }
                })
        })
    }, [id, role, refresh])

    const handleStatus = (status) => {
        UpdateStatus(id, status).then(res => setRefresh(!refresh))
    }

    const actions = [
        { name: 'Details', icon: <DescriptionIcon />, module: 1, component: <Details onRefresh={() => setRefresh(!refresh)} /> },
        { name: 'Profile', icon: <InfoIcon />, module: 2, component: <Profile onRefresh={() => setRefresh(!refresh)} /> },
        { name: 'Address', icon: <MailIcon />, module: 3, component: <Address /> },
        { name: 'Users', icon: <PeopleIcon />, module: 4, component: <Users /> },
        { name: 'Invoice Cycles', icon: <PayIcon />, module: 5, component: <Invoices /> },
        { name: 'Invoices', icon: <ListIcon />, module: 9, component: <Statements /> },
        { name: 'Permits', icon: <HelpIcon />, module: 10, component: <Permits /> },
        { name: 'Notes', icon: <CommentIcon />, module: 6, component: <NotesView module={3} entityId={id} /> },
        { name: 'Notices', icon: <NotesIcon />, module: 7, component: <NoticeView module={3} entityId={id} /> },
        { name: 'Attachments', icon: <AttachmentIcon />, module: 8, component: <AttachmentView module={3} entityId={id} /> },
        { name: 'Events', icon: <EventIcon />, module: 8, component: <EventsView entityId={id} /> },
    ]
    /*Need to Change templates below. Make it One*/
    return <React.Fragment>
        {id !== 0 && id !== '0' && <h6 className='text-center'><small className='text-muted'>Company Id: {id}</small></h6>}
        <SpeedTemplate link='#company' entityId={id} entity={details} access={access} actions={actions} />
        <StatusTemplate settings={access} handleClick={handleStatus}>
            <ModuleTemplate entity={details} settings={access} module={1} component={<Details onRefresh={() => setRefresh(!refresh)} />} />
            <ModuleTemplate entity={details} settings={access} module={2} component={<Profile entityId={id} onRefresh={() => setRefresh(!refresh)} />} />
            <ModuleTemplate entity={details} settings={access} module={3} component={<Address entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={4} component={<Users entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={5} component={<Invoices entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={9} component={<Statements entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={10} component={<Permits entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={6} component={<NotesView module={3} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={7} component={<NoticeView module={3} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={8} component={<AttachmentView module={3} entityId={id} />} />
            <ModuleTemplate entity={details} settings={access} module={9} component={<EventsView  entityId={id} />} />
        </StatusTemplate>
    </React.Fragment>
}