import React, { useRef, useState } from 'react';
import { FormView, Field } from '../../components/form/Index';
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

// REST
import { ForgotPassword, ForgotUsername } from '../../REST/authorize';

export default function LoginReset() {
    const [invalidemail, setInvalidEmailAddress] = useState(false)
    const formView = useRef(null);

    let history = useHistory();
    let params = useParams()

    const sendResetLinks = () => {
        let result = formView.current.getResult();
        if (result) {
            if (params.type === 'U')
                ForgotUsername({ Email: result.emailaddress }).then(response => {
                    if (response) {
                        setInvalidEmailAddress(false);
                        history.push('/login')
                    }
                }).catch(ex => {
                    console.log(ex)
                    setInvalidEmailAddress(true)
                })
            else ForgotPassword({ Email: result.emailaddress }).then(response => {
                if (response) {
                    setInvalidEmailAddress(false);
                    history.push('/login')
                }
            }).catch(ex => {
                console.log(ex)
                setInvalidEmailAddress(true)
            })
        }

    }

    return (
        <div className='row h-100'>
            <div className='offset-sm-3 offset-md-4 col-sm-6 col-md-4 my-auto'>
                <form onSubmit={(e) => { e.preventDefault(); sendResetLinks(); }}>
                    <Paper elevation={2} className='p-3'>
                        <h4 className='lead text-center'>{`Forgot your ${params.type === 'U' ? 'Username' : 'Password'}`} </h4>
                        {invalidemail && <small className="text-danger">Invalid email address</small>}
                        <FormView ref={formView}>
                            <Field.TextBox field='emailaddress' label={`${params.type === 'U' ? 'Enter Email Address' : 'Enter Username'}`} required />
                            <Button type='submit' size='large' className='mt-2' fullWidth variant='outlined' color='secondary'>OK</Button>
                            <small>  <a href='#login'> Back to Login</a></small>
                        </FormView>
                    </Paper>
                </form>
            </div>
        </div>)
}