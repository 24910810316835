import React from 'react';
import CRUDView from '../../../components/crud';
import { GetPermits } from '../../../REST/administration';
import { useEffect } from 'react';
import { useState } from 'react';

export default function AttachmentTypes() {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        GetPermits().then(e => {
            if (Array.isArray(e.data)) {
                setOptions(e.data.map(x => { return { label: x.name, value: x.id } }))
            }
        })
    }, [])

    const config = [
        { field: 'name', title: 'Name', type: 'text', isGrid: true },
        { field: 'description', title: 'Description', type: 'textarea', isGrid: true },
        { field: 'permitId', title: 'Permit Type', type: 'select', options: options, isGrid: true },
        { field: 'issueReq', title: 'Required For Issue', type: 'checkbox', isGrid: true },
    ]

    return <CRUDView link='#admin' url={`${process.env.REACT_APP_API_URL}/AttachmentType`} columns={config} title='Attachment Types' allowDelete={false} id='id' />
}

