import React, { forwardRef, Fragment, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { DeleteItems, PayItems } from '../../REST/statement';
import PaymentView from './payment';
import { authenticationService } from '../../components/authorization';
import ConfirmView from '../../components/confirm';

function PaymentTotal({ editable = true, display = true, charges = [], selected = [], refresh, handleRefresh }, ref) {
    const delConf = useRef(null);
    const payConf = useRef(null);
    const { id } = useParams();
    const [pay, setPay] = useState({ open: false, invoiceId: null });
    const history = useHistory();

    const handlePayment = () => {
        setPay({ open: true, invoiceId: id })
        window.open(process.env.REACT_APP_PROD_URL + '/#/payment/' + id)
    }

    const handleDelete = () => {
        DeleteItems(id, selected).then(response => {
            handleRefresh()
        })
        delConf.current.onClose()
    }

    const paySelected = () => {
        PayItems(id, selected).then(response => {
            history.push('/invoices/' + response.data)
        })
        payConf.current.onClose()
    }

    const items = charges.filter(e => !e.isPaid)
    if (items.length > 0) {
        return <React.Fragment>
            <ConfirmView ref={delConf} title='Delete Items' setting='INVOICE_DELETE_ITEMS' onSubmit={handleDelete} onClose={() => delConf.current.onClose()} />
            <ConfirmView ref={payConf} title='Pay Items' setting='INVOICE_PAY_ITEMS' onSubmit={paySelected} onClose={() => payConf.current.onClose()} />
            <PaymentView open={pay.open} handleClose={() => { setPay({ open: false, invoiceId: null }); handleRefresh(); }} invoiceId={pay.invoiceId} />
            <div className='row'>
                <div className='col-12 text-right'>
                    <b>Subtotal ({items.length} items): ${(items.map(e => e.charge).reduce((a, c) => a + c)).toFixed(2)}</b>
                </div>
            </div>
            {selected.length > 0 && <React.Fragment>
                <Button disabled={!authenticationService.isAdmin()} variant='outlined' onClick={() => delConf.current.onOpen()} color='secondary'>Delete ({selected.length}) Items</Button>
                <Button disabled={selected.length === items.length} onClick={() => payConf.current.onOpen()} variant='outlined' color='secondary'>Pay ({selected.length}) Items</Button>
            </React.Fragment>}
            {display && <React.Fragment>
                <Button className='float-right' disabled={!editable} onClick={handlePayment} variant='outlined'>Pay Now</Button>
                <div className='clearfix' />
            </React.Fragment>}
        </React.Fragment>
    }
    return <Fragment>
        <div className='row'>
            <div className='col-12 text-right'>
                <b>Total Due: ${'0.00'}</b>
            </div>
        </div>
    </Fragment>
}

export default forwardRef(PaymentTotal);