import React from 'react';
import Paper from '@material-ui/core/Paper';
import { simpleDate } from '../../components/dates';


function DetailsView({ editable = true, display = true, charges = [], entity }, ref) {

    if (entity)
        return <Paper elevation={2}>
            <div className='col-12 p-2'>
                {!entity.temporary && <h5 className='text-center'><small className='text-muted'>Invoice Number: {entity.invoiceNumber}</small></h5>}
                <hr />
                <h6><small className='text-muted'>Invoice Date: {simpleDate(entity.invoiceDate)}</small></h6>
                <h6><small className='text-muted'>Fiscal Year:{entity.fiscalYear}</small></h6>
                <h6><small className='text-muted'>Finalization Due:{simpleDate(entity.finalizeDue)}</small></h6>
                <h6><small className='text-muted'>Finalized Date: {simpleDate(entity.finalizedDate)}</small></h6>
                <h6><small className='text-muted'>Due Date: {simpleDate(entity.dueDate)}</small></h6>
            </div>
        </Paper>
    return <div />
}

export default React.forwardRef(DetailsView);