import React, { useEffect } from 'react';
import { GetFields, AddSchedule, UpdateSchedule, GetSchedule } from '../../../REST/administration';
import { useState } from 'react';
import CRUDView from '../../../components/crud';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory, useParams } from 'react-router-dom';
import { useRef } from 'react';
import { FormView, Field } from '../../../components/form/Index';
import Button from '@material-ui/core/Button';

const modules = [
    { label: 'Applications', value: 1 },
    { label: 'Permits', value: 2 },
    { label: 'Company', value: 3 },
    { label: 'Invoices', value: 4 },
    { label: 'Events', value: 5 }
]

const trigger = [
    { label: 'Before', value: 1 },
    { label: 'After', value: 2 }
]

export default function ScheduleStatus() {
    const history = useHistory();
    const [fields, setFields] = useState([])
    const { id } = useParams()
    useEffect(() => {
        GetFields().then(resp => {
            if (Array.isArray(resp.data))
                setFields(resp.data)
        })
    }, [])

    const columns = [
        { title: 'Schedule Name', field: 'name', type: 'text', isGrid: true },
        { title: 'Schedule Description', field: 'description', type: 'text', isGrid: true }
    ]

    const actions = [
        { icon: AddIcon, isFreeAction: true, onClick: () => history.push('/admin/schedules/0') },
        { icon: EditIcon, onClick: (evt, rowData) => history.push('/admin/schedules/' + rowData.id) }
    ]

    if (id !== null && id !== undefined)
        return <DetailsView fields={fields} />

    return <CRUDView url={process.env.REACT_APP_API_URL + '/Schedule'} actions={actions} title='Status Schedules' columns={columns} id='id' allowAdd={false} allowEdit={false} allowDelete={true} />
}

function DetailsView({ fields = [{ values: [], status: [] }] }) {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const formView = useRef(null);
    const history = useHistory();

    useEffect(() => {
        if (id !== '0' && id !== 0)
            GetSchedule(id).then(resp => {
                setData(resp.data)
            })
    }, [id])

    const handleChange = () => {
        let result = formView.current.getCurrentResult();
        setData({ ...data, ...result })
    }

    const getFields = () => {
        if (data && data.module) {
            return (fields.find(e => String(e.module) === String(data.module)) || { values: [] }).values.map(e => { return { label: e, value: e } })
        }
        return []
    }

    const getStatus = () => {
        if (data && data.module) {
            return (fields.find(e => String(e.module) === String(data.module)) || { status: [] }).status
        }
        return []
    }

    const handleSave = () => {
        let result = formView.current.getResult()
        if (result) {
            if (id !== '0' && id !== 0)
                UpdateSchedule({ ...data, ...result })
            else
                AddSchedule(result).then(resp => history.push('/admin/schedules/' + resp.data.id))
        }
    }

    return <div className='row'><div className='col-6'>
        <FormView data={data} ref={formView}>
            <Field.TextBox field='name' label='Name' required />
            <Field.TextArea field='description' label='Description' />
            <Field.DropDown field='module' label='Module' options={modules} required changeEvt={handleChange} />
            <Field.DropDown field='columnName' label='Column Name' options={getFields()} required changeEvt={handleChange} />
            <div className='row'>
                <Field.DropDown className='col' field='currentStatus' label='Current Status' options={getStatus()} required />
                <Field.DropDown className='col' field='nextStatus' label='Next Status' options={getStatus()} required />
            </div>
            <div className='row'>
                <Field.TextBox className='col' number field='daysNumber' label='Number of Days' required />
                <Field.DropDown className='col' number field='triggerType' label='Change Type' options={trigger} required />
            </div>
            <div className='row'>
                <div className='col'>
                    <Button onClick={handleSave} fullWidth variant='outlined'>Save</Button>
                </div>
                <div className='col'>
                    <Button fullWidth variant='outlined' href='#admin/schedules'>Cancel</Button>
                </div>
            </div>
        </FormView></div></div>

}